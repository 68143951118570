export const embedCodeFieldDescription = (
  <small className="block self-center pb-4 text-gray-500">
    Livestream player embed code (web only).
  </small>
)

export const sourceUrlFieldDescription = (
  <small className="block self-center pb-4 text-gray-500">
    Livestream source URL. Only unencrypted HLS (<code>m3u8</code>) and DASH (
    <code>mpd</code>) formats are supported.
  </small>
)

export const pbsStationManagerLivestreamFeedCidFieldDescription = (
  <small className="block self-center pb-4 text-gray-500">
    {
      "The ID associated with this channel's livestream data in PBS Station Manager."
    }
  </small>
)

export const vimeoLiveEventIdFieldDescription = (
  <small className="block self-center pb-4 text-gray-500">
    Navigate to <a href="https://vimeo.com/library/events">Vimeo Live Events</a>
    , select the live event and copy the ID from the page URL.
  </small>
)
