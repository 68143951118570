import { useEffect, useState } from 'react'

import { AiOutlineCloseCircle } from 'react-icons/ai'
import {
  FaCheckCircle,
  FaTimesCircle,
  FaUserCheck,
  FaUserEdit,
  FaUserSlash,
} from 'react-icons/fa'

import {
  FieldError,
  Form,
  Label,
  Submit,
  TextField,
  useForm,
} from '@redwoodjs/forms'

import Button from 'src/components/Custom/Button/Button'
import Loader from 'src/components/Custom/Loader/Loader'
import PrimaryModal from 'src/components/Custom/Modal/PrimaryModal'
import MultiSelectDropdown, {
  MultiSelectOption,
} from 'src/components/Custom/MultiselectDropdown/MultiselectDropdown'
import { userRoleMap } from 'src/lib/enums'

interface UserEdtiorProps {
  id: string
  name: string
  email: string
  roles: string[]
  active: boolean
  handleDeactivateUser?: (id: string) => void
  handleActivateUser?: (id: string) => void
  updateUser?: (
    id: string,
    user: { name: string; email: string; roles: string[]; stationId: string }
  ) => Promise<void>
  newUser?: boolean
  canDeactivate?: boolean
  createUser?: (inputData: {
    name: string
    email: string
    roles: string[]
  }) => void
  setNewUser?: React.Dispatch<React.SetStateAction<boolean>>
  stationId: string
  roleType: string
  loading: boolean
  onChange?: () => void
  onCancelChanges?: () => void
}

const UserEditor = ({
  id,
  name,
  updateUser,
  handleDeactivateUser,
  handleActivateUser,
  roles,
  active,
  email,
  newUser,
  canDeactivate,
  createUser,
  setNewUser,
  stationId,
  roleType,
  loading,
  onChange,
  onCancelChanges,
}: UserEdtiorProps) => {
  const [roleOptions, setRoleOptions] = useState<Array<MultiSelectOption>>([])

  const formMethods = useForm()
  const selectedRoles = formMethods.watch('selectedRoles', [])

  const [showDeactivateModal, setShowDeactivateModal] = useState(false)
  const [showActivateModal, setShowActivateModal] = useState(false)
  const [isEditable, setIsEditable] = useState(false)

  useEffect(() => {
    formMethods.register('selectedRoles', {
      required: 'Please select at least one role',
      validate: (value) =>
        value.length > 0 || 'Please select at least one role',
    })
  }, [formMethods])

  useEffect(() => {
    const transformedArray = Object.entries(userRoleMap)
      .filter(([key, _]) => key.startsWith(roleType))
      .map(([key, value], index) => ({
        id: index + 1,
        value: key,
        label: value,
      }))

    setRoleOptions(transformedArray)
  }, [roleType])

  useEffect(() => {
    if (!newUser) {
      const selectedRoles = []

      roles.forEach((str) => {
        const match = roleOptions.find((obj) => obj.value === str)
        if (match) {
          selectedRoles.push(match)
        }
      })

      formMethods.setValue('selectedRoles', selectedRoles)
    }
  }, [roleOptions, formMethods, newUser, roles])

  const nameValidationRules = {
    required: `Please provide a name.`,
    validate: {
      aboutText: (value) => {
        if (!value || value.trim() === '') {
          return `Please provide a name.`
        }

        return true
      },
    },
  }

  const emailValidationRules = {
    required: 'Please enter a valid email address.',
    validate: {
      email: (value) => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/

        if (!value) {
          return 'Please enter a valid email address.'
        }

        if (typeof value !== 'string' || !emailPattern.test(value)) {
          return 'Please enter a valid email address.'
        }

        return true
      },
    },
  }

  const convertRolesArray = () => {
    const arrayOfLabels = selectedRoles.map(
      (obj: MultiSelectOption) => obj.value
    )
    return arrayOfLabels
  }

  const onSubmit = (data) => {
    if (newUser) {
      return createUser({
        name: data.name,
        email: data.email,
        roles: convertRolesArray(),
      })
    }
    updateUser(id, {
      name: data.name,
      email: data.email,
      roles: convertRolesArray(),
      stationId: stationId,
    }).then(() => setIsEditable(false))
  }

  const handleRolesChange = (options: Array<MultiSelectOption>) => {
    formMethods.setValue('selectedRoles', options)
    formMethods.trigger('selectedRoles')
    onChange()
  }

  const rolesString = selectedRoles
    .map((obj: MultiSelectOption) => obj.label)
    .join(', ')

  return (
    <div className="flex w-full items-center justify-between border-b-2 border-gray-200 px-[24px] py-[20px]">
      <Form
        className="flex w-full flex-col"
        onSubmit={onSubmit}
        formMethods={formMethods}
      >
        <div className="mb-4 flex">
          <div className="mr-2 w-1/2">
            <Label
              name="Name"
              className="block text-sm font-medium text-gray-700"
            />
            {isEditable || newUser ? (
              <>
                <TextField
                  name="name"
                  defaultValue={name}
                  validation={nameValidationRules}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={onChange}
                />
                <FieldError name="name" className="text-red-700" />
              </>
            ) : (
              <p>{name || <em>Not set</em>}</p>
            )}
          </div>
          <div className="ml-2 w-1/2">
            <Label
              name="Email"
              className="block text-sm font-medium text-gray-700"
            />
            {isEditable || newUser ? (
              <>
                <TextField
                  name="email"
                  defaultValue={email}
                  validation={emailValidationRules}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={onChange}
                />
                <FieldError name="email" className="text-red-700" />
              </>
            ) : (
              <p>{email || <em>Not set</em>}</p>
            )}
          </div>
        </div>

        <div className="flex">
          <div className="w-full">
            {isEditable || newUser ? (
              <>
                <MultiSelectDropdown
                  title="Roles"
                  options={roleOptions}
                  selectedOptions={selectedRoles}
                  onSelectedOptionsChange={handleRolesChange}
                />

                <FieldError name="selectedRoles" className="text-red-700" />
              </>
            ) : (
              <>
                <Label
                  name="Roles"
                  className="block text-sm font-medium text-gray-700"
                />
                <span>{rolesString}</span>
              </>
            )}
            <div className="mt-4">
              <Label
                name="Active"
                className="block text-sm font-medium text-gray-700"
              />
              <span>
                {active ? (
                  <FaCheckCircle
                    className="text-green-500"
                    size="24px"
                    title="Active"
                  />
                ) : (
                  <FaTimesCircle
                    className="text-red-500"
                    size="24px"
                    title="Active"
                  />
                )}
              </span>
            </div>
          </div>

          {isEditable || newUser ? (
            <div className="flex w-full flex-col justify-end">
              <div className="flex w-full justify-end">
                <Submit
                  disabled={loading}
                  className="w-min-{64px} mr-4 inline-flex h-10 items-center justify-center rounded-lg bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-800"
                >
                  {loading ? <Loader /> : 'Save'}
                </Submit>
              </div>
              <div className="flex w-full justify-end">
                <Button
                  title="Cancel"
                  outlineColor="ml-4 mt-4 border-red-600 text-red-600 hover:border-red-700 hover:text-red-700"
                  icon={<AiOutlineCloseCircle size="20px" />}
                  onClick={() => {
                    setIsEditable(false)
                    if (setNewUser) {
                      setNewUser(false)
                    }
                    onCancelChanges()
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-3">
              <Button
                title="Update"
                outlineColor="inline-flex h-10 items-center justify-center rounded-lg bg-indigo-600 text-sm font-medium text-white hover:bg-indigo-800"
                icon={<FaUserEdit size="15px" />}
                onClick={() => setIsEditable(true)}
                isLoading={loading}
              />
              {!newUser && canDeactivate && (
                <>
                  {active ? (
                    <Button
                      title="Deactivate"
                      outlineColor="bg-red-700 text-white hover:bg-red-800 hover:text-white"
                      icon={<FaUserSlash size="15px" />}
                      onClick={() => setShowDeactivateModal(true)}
                      isLoading={loading}
                    />
                  ) : (
                    <Button
                      title="Activate"
                      outlineColor="bg-green-700 text-white hover:bg-green-800 hover:text-white"
                      icon={<FaUserCheck size="15px" />}
                      onClick={() => setShowActivateModal(true)}
                      isLoading={loading}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </Form>

      <PrimaryModal
        modalText={
          <p>
            Are you sure you wish to deactivate <strong>{name}</strong>?
          </p>
        }
        buttonList={[
          {
            label: 'Deactivate',
            id: 'remove',
            type: 'danger',
            onClick: () => {
              handleDeactivateUser(id)
              setShowDeactivateModal(false)
              setIsEditable(false)
            },
          },
          {
            label: 'Cancel',
            id: 'cancel',
            type: 'secondary',
            onClick: () => setShowDeactivateModal(false),
          },
        ]}
        showModal={showDeactivateModal}
        setShowModal={setShowDeactivateModal}
      />

      <PrimaryModal
        modalText={
          <p>
            Are you sure you wish to activate <strong>{name}</strong>?
          </p>
        }
        buttonList={[
          {
            label: 'Activate',
            id: 'activate',
            type: 'success',
            onClick: () => {
              handleActivateUser(id)
              setShowActivateModal(false)
              setIsEditable(false)
            },
          },
          {
            label: 'Cancel',
            id: 'cancel',
            type: 'secondary',
            onClick: () => setShowActivateModal(false),
          },
        ]}
        showModal={showActivateModal}
        setShowModal={setShowActivateModal}
      />
    </div>
  )
}

export default UserEditor
