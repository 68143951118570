import { useEffect, useState } from 'react'

import { GetNewShows } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { Metadata, useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { ContentItemType } from 'src/common/types'
import Button from 'src/components/Custom/Button/Button'
import ContentListingTable from 'src/components/Custom/ContentListingTable/ContentListingTable'
import Dropdown from 'src/components/Custom/Dropdown/Dropdown'
import HeadingLevel from 'src/components/Custom/Heading/Heading'
import PrimaryModal from 'src/components/Custom/Modal/PrimaryModal'
import { UserRole } from 'src/lib/enums'
import { getImage } from 'src/lib/images'

import { GET_NEW_SHOWS } from '../queries'

import { newShowsByDateOptions, newShowsHeader } from './constants'

interface NewShowsListingState {
  showEditModal: boolean
  selectedItemId?: string
}

interface NewShowsFilters {
  limit?: number
  skip?: number
}

interface NewShowsRouteParams {
  page?: number
  recency?: number
}

const NewShowsListing = (params: NewShowsRouteParams) => {
  // Set default byDate option ("One week").
  const defaultDateOption = newShowsByDateOptions[0]
  const { recency = parseInt(defaultDateOption.value), page = 1 } = params
  const calculatedPage = typeof page === 'string' ? parseInt(page, 10) : page

  const { currentUser } = useAuth()
  const isGlobalUser =
    currentUser?.roles?.includes(UserRole.GLOBAL_PROGRAMMER) ||
    currentUser?.roles?.includes(UserRole.ADMIN)

  const [currentPage, setCurrentPage] = useState(calculatedPage)
  const [searchParams, setSearchParams] = useState<NewShowsRouteParams>({
    page,
    recency,
  })
  const [content, setContent] = useState<ContentItemType[]>([])
  const [state, setState] = useState<NewShowsListingState>({
    showEditModal: false,
    selectedItemId: null,
  })
  const [totalItems, setTotalItems] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const resultsPerPage = 25
  const [filters, setFilters] = useState<NewShowsFilters>({
    limit: resultsPerPage,
    skip: resultsPerPage * (currentPage - 1),
  })

  const currentZeroDate = () => {
    const date = new Date()
    date.setHours(0, 0, 0, 0)
    return date
  }

  const getDefaultDate = (): Date => {
    const currentDate = currentZeroDate()
    currentDate.setDate(currentDate.getDate() - recency)
    return currentDate
  }
  const [byDate, setByDate] = useState<Date>(getDefaultDate())

  const { loading } = useQuery<GetNewShows>(GET_NEW_SHOWS, {
    variables: {
      byDate,
      filters: { ...filters },
    },
    onCompleted: ({ newShows: data }) => {
      setTotalItems(data?.total)
      const normalizedShows = data?.normalizedShows
      if (normalizedShows) {
        const elements = normalizedShows.map((show) => {
          const poster = getImage(show.images)
          return {
            id: show.id,
            title: show.title,
            image: poster.image,
            createdAt: new Date(show.createdAt).toDateString(),
          }
        })
        setContent(elements)
      }
    },
    onError: () => {
      setTotalItems(0)
      setPageCount(0)
    },
  })

  useEffect(() => {
    setPageCount(Math.ceil(totalItems / filters.limit))
  }, [filters.limit, totalItems])

  // Listen for changes to route params and update filters, dates.
  useEffect(() => {
    const toPage = params.page ? Number(params.page) : 1
    setCurrentPage(toPage)
    setFilters((old) => ({
      ...old,
      skip: old.limit * (toPage - 1),
    }))
  }, [params])

  // When searchParams are updated, update URL in browser.
  useEffect(() => {
    navigate(routes.globalMetaDataManagerNewShows({ ...searchParams }), {
      replace: true,
    })
  }, [searchParams])

  const getDateOptionLabelByValue = (value: string): string => {
    return newShowsByDateOptions
      .filter((opt) => opt.value === value)
      .map((opt) => opt.label)[0]
  }

  const handleDateChange = (option: { label: string; value: string }) => {
    const { value } = option
    const recency = parseInt(value)
    const currentDate = currentZeroDate()
    currentDate.setDate(currentDate.getDate() - recency)
    setByDate(currentDate)

    setCurrentPage(1)
    const newRouteParams: NewShowsRouteParams = {}
    newRouteParams.recency = recency
    setSearchParams((old) => ({
      ...old,
      ...newRouteParams,
      page: 1,
    }))
  }

  const handlePageChange = (toPage: number) => {
    setFilters((old) => ({ ...old, skip: old.limit * (toPage - 1) }))
    setCurrentPage(toPage)
    const newSearchParams = { ...searchParams, page: toPage }
    navigate(routes.globalMetaDataManagerNewShows(newSearchParams), {
      replace: false,
    })
  }

  const actionsList = (itemData) => {
    return (
      <div className="flex flex-col p-2">
        <Button
          title="Edit"
          onClick={() =>
            setState({ showEditModal: true, selectedItemId: itemData.id })
          }
          backgroundColor="bg-blue-600"
          customButtonClass="mb-2"
        />
      </div>
    )
  }

  return (
    <div className="w-full p-4">
      <Metadata title="New Shows" />
      <HeadingLevel level={1}>New Shows</HeadingLevel>
      <div className="mt-5">
        <div>
          These shows just came in from Media Manager and need a metadata pass
        </div>
        <div className="pb-4 pt-6">
          <span className="pl-5 pr-2">How new?</span>
          <Dropdown
            options={newShowsByDateOptions}
            buttonText={getDateOptionLabelByValue(recency.toString())}
            onOptionsChange={handleDateChange}
          />
        </div>
        <ContentListingTable
          contentList={content}
          tableHeading={newShowsHeader}
          handleSort={() => {}}
          actionsList={actionsList}
          emptyMessage="We did it! There are no more new shows that need attention."
          paginationConfig={{
            limit: filters.limit,
            page: currentPage,
            pageCount,
            totalItems,
          }}
          onPageChange={handlePageChange}
          isLoading={loading}
        />
        <PrimaryModal
          modalText={
            'If you edit this show, it will  no longer appear in the New Shows queue'
          }
          buttonList={[
            {
              label: 'Continue',
              id: 'continue',
              type: 'primary',
              onClick: () => {
                navigate(
                  isGlobalUser
                    ? routes.editGlobalShow({ id: state.selectedItemId ?? '' })
                    : routes.editShow({ id: state.selectedItemId ?? '' })
                )
              },
            },
            {
              label: 'Cancel',
              id: 'cancel',
              type: 'secondary',
              onClick: () => {
                setState({ showEditModal: false })
              },
            },
          ]}
          showModal={state.showEditModal}
          setShowModal={(showEditModal) => setState({ showEditModal })}
        />
      </div>
    </div>
  )
}

export default NewShowsListing
