import { IoIosCheckmarkCircle, IoIosCloseCircle } from 'react-icons/io'
import { GetLsiMemberStations } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'

import Dropdown from 'src/components/Custom/Dropdown/Dropdown'
import HeadingLevel from 'src/components/Custom/Heading/Heading'

interface StationProps {
  station: GetLsiMemberStations['stations'][number]
}

interface ColorInfoProps {
  color: string
  label: string
}

const ColorInfo = ({ color, label }: ColorInfoProps) => {
  return (
    <div className="my-1 grid w-full grid-cols-1 items-center gap-4 2xl:grid-cols-2">
      {color ? (
        <div
          style={{ backgroundColor: color }}
          className="h-6 w-6 justify-self-center rounded-full"
        />
      ) : (
        <em className="text-center">Not set</em>
      )}
      <span className="justify-self-center 2xl:justify-self-auto">{label}</span>
    </div>
  )
}

const Station = ({ station }: StationProps) => {
  const dropdownLinks = [
    {
      label: 'General',
      value: 'general',
    },
    {
      label: 'Livestreams',
      value: 'livestreams',
    },
    {
      label: 'Users',
      value: 'users',
    },
  ]
  const onDropdownSelect = (option: { label: string; value: string }) => {
    if (option.value === 'general') {
      navigate(routes.editLocalStation({ id: station.id }))
    } else if (option.value === 'livestreams') {
      navigate(routes.editLocalStationLivestreams({ id: station.id }))
    } else if (option.value === 'users') {
      navigate(routes.editLocalStationUsers({ id: station.id }))
    }
  }

  return (
    <div className="grid w-full grid-cols-5 items-center gap-4 border-b-2 border-gray-200 py-[20px]">
      <div className="col-span-1 flex items-center justify-center">
        {station.smallColorLogo ? (
          <img
            className="h-auto w-full"
            src={station.smallColorLogo.image}
            alt="station color logo"
          />
        ) : (
          <em>Not set</em>
        )}
      </div>

      <div className="col-span-1 flex items-center justify-center">
        <HeadingLevel level={2}>{station.callSign}</HeadingLevel>
      </div>

      <div className="col-span-1 flex flex-col items-center justify-center">
        <HeadingLevel level={3}>Colors:</HeadingLevel>
        <div className="mt-2 flex flex-col items-center">
          <ColorInfo color={station.colorPrimary} label="Primary" />
          <ColorInfo color={station.colorSecondary} label="Secondary" />
          <ColorInfo color={station.colorBackground} label="Background" />
        </div>
      </div>

      <div className="col-span-1 flex flex-col items-center justify-center">
        <HeadingLevel level={3}>Border station:</HeadingLevel>
        {station.borderStation ? (
          <IoIosCheckmarkCircle
            className="mr-1 inline size-6 fill-green-600"
            title="Yes"
          />
        ) : (
          <IoIosCloseCircle
            className="mr-1 inline size-6 fill-red-600"
            title="No"
          />
        )}
      </div>

      <div className="col-span-1 flex items-center justify-center">
        <Dropdown
          options={dropdownLinks}
          onOptionsChange={onDropdownSelect}
          buttonText="Edit"
        />
      </div>
    </div>
  )
}

export default Station
