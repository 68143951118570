export interface LogoProps {
  height?: number
  shade?: 'dark' | 'light'
  variant?: 'horizontal' | 'stacked' | 'stackedAlternative'
  width?: number
}

const Logo: React.FC<LogoProps> = ({
  height,
  shade = 'light',
  variant = 'horizontal',
  width,
}) => {
  const svgProps: React.SVGProps<SVGSVGElement> = {
    fill: 'none',
    height,
    preserveAspectRatio: 'xMinYMin meet',
    width,
    xmlns: 'http://www.w3.org/2000/svg',
  }
  const title = <title>Local Public logo</title>
  if (variant == 'horizontal') {
    if (shade == 'dark') {
      return (
        <svg {...svgProps} viewBox="0 0 461 57">
          {title}
          <path
            fill="#fff"
            d="M3.808 51V11.8h9.016v30.912h16.688V51zm48.34.672q-6.049 0-10.697-2.576-4.647-2.632-7.28-7.168-2.632-4.592-2.632-10.472 0-5.936 2.632-10.528 2.633-4.591 7.224-7.168 4.649-2.576 10.64-2.576 5.993 0 10.584 2.576 4.649 2.576 7.28 7.168 2.632 4.591 2.632 10.528 0 5.88-2.632 10.472-2.575 4.536-7.168 7.168-4.592 2.576-10.584 2.576m0-8.288q3.247 0 5.767-1.512t3.92-4.2q1.456-2.744 1.456-6.216 0-3.528-1.456-6.216-1.4-2.744-3.976-4.256-2.52-1.568-5.824-1.568t-5.88 1.568q-2.52 1.512-3.976 4.256-1.4 2.688-1.4 6.216 0 3.473 1.456 6.216 1.456 2.688 4.032 4.2t5.88 1.512m43.567 8.288q-5.824 0-10.304-2.576-4.425-2.632-7-7.168-2.52-4.591-2.52-10.528 0-5.936 2.52-10.472 2.52-4.591 7-7.168 4.48-2.632 10.304-2.632 4.256 0 7.784 1.512 3.584 1.456 6.16 4.144A18.6 18.6 0 0 1 113.691 23l-8.456 2.856q-1.344-2.911-3.808-4.648-2.465-1.792-5.712-1.792-3.136 0-5.544 1.512-2.352 1.512-3.696 4.256-1.344 2.688-1.344 6.216t1.344 6.272q1.4 2.688 3.808 4.256 2.465 1.512 5.6 1.512 3.416 0 5.712-1.848 2.352-1.848 3.752-4.816l8.456 2.856a19.1 19.1 0 0 1-4.032 6.328q-2.576 2.688-6.16 4.2t-7.896 1.512M115.346 51l15.232-39.2h9.632L155.442 51h-9.52l-2.8-7.448h-15.456l-2.8 7.448zm15.288-15.4h9.52l-4.76-12.712zM159.722 51V11.8h9.016v30.912h16.688V51z"
          />
          <path
            fill="#02ADED"
            d="M268.808 51V11.8h15.4q4.255 0 7.56 1.736 3.304 1.68 5.152 4.704 1.904 2.968 1.904 6.944 0 3.92-1.848 6.944-1.793 3.024-5.04 4.76-3.248 1.736-7.448 1.736h-6.664V51zm9.016-20.664h6.44q2.352 0 3.808-1.4 1.512-1.455 1.512-3.752t-1.568-3.696q-1.568-1.455-4.032-1.456h-6.16zm41.118 21.392q-5.04 0-8.736-1.904-3.64-1.904-5.6-5.376-1.96-3.528-1.96-8.344V11.8h9.016v24.304q0 3.36 1.96 5.32 2.016 1.96 5.432 1.96t5.376-1.96q2.016-1.96 2.016-5.32V11.8h9.016v24.304q0 4.816-2.016 8.344-1.959 3.472-5.712 5.376-3.695 1.904-8.792 1.904M342.527 51V11.8h13.72q6.048 0 9.856 2.8t3.808 8.568a7.74 7.74 0 0 1-1.064 3.92q-1.065 1.792-3.024 3.248 2.631 1.345 4.088 3.64 1.456 2.295 1.456 5.6 0 5.488-3.696 8.456Q364.031 51 358.095 51zm8.792-23.8h4.648q2.127 0 3.416-1.064 1.287-1.12 1.288-3.08 0-2.015-1.4-3.08-1.345-1.12-3.64-1.12h-4.312zm0 15.848h5.768q2.295 0 3.64-1.176 1.344-1.232 1.344-3.136 0-1.96-1.4-3.136-1.401-1.176-3.864-1.176h-5.488zM376.871 51V11.8h9.016v30.912h16.688V51zm30.515 0V11.8h9.016V51zm34.321.672q-5.824 0-10.304-2.576-4.424-2.632-7-7.168-2.52-4.591-2.52-10.528 0-5.936 2.52-10.472 2.52-4.591 7-7.168 4.48-2.632 10.304-2.632 4.256 0 7.784 1.512 3.584 1.456 6.16 4.144A18.6 18.6 0 0 1 459.683 23l-8.456 2.856q-1.344-2.911-3.808-4.648-2.464-1.792-5.712-1.792-3.137 0-5.544 1.512-2.352 1.512-3.696 4.256-1.344 2.688-1.344 6.216t1.344 6.272q1.4 2.688 3.808 4.256 2.464 1.512 5.6 1.512 3.415 0 5.712-1.848 2.352-1.848 3.752-4.816l8.456 2.856a19.1 19.1 0 0 1-4.032 6.328q-2.576 2.688-6.16 4.2t-7.896 1.512"
          />
          <path
            fill="#02ADEF"
            d="M252.005 25.282c5.082 2.954 4.669 9.859-.735 12.288l-27.277 12.264c-11.717 5.268-25.411-2.533-25.411-14.475V7.069c0-5.567 6.716-8.942 11.852-5.956z"
          />
          <path
            fill="#8DD6F8"
            d="M234.534 32.79c5.35 2.813 5.102 9.949-.433 12.446l-22.803 10.287c-5.859 2.643-12.716-1.257-12.716-7.233V26.077c0-5.427 6.416-8.823 11.559-6.118z"
          />
          <path
            fill="#fff"
            d="M220.206 42.885c3.744 2.055 3.512 7.087-.407 8.847l-10.103 4.537c-5.126 2.302-11.114-1.111-11.114-6.335V39.92c0-3.918 4.672-6.338 8.345-4.322z"
          />
        </svg>
      )
    } else {
      return (
        <svg {...svgProps} viewBox="0 0 461 57">
          {title}
          <path
            fill="#2E3091"
            d="M3.808 51V11.8h9.016v30.912h16.688V51zm48.34.672q-6.049 0-10.697-2.576-4.647-2.632-7.28-7.168-2.632-4.592-2.632-10.472 0-5.936 2.632-10.528 2.633-4.591 7.224-7.168 4.649-2.576 10.64-2.576 5.993 0 10.584 2.576 4.649 2.576 7.28 7.168 2.632 4.591 2.632 10.528 0 5.88-2.632 10.472-2.575 4.536-7.168 7.168-4.592 2.576-10.584 2.576m0-8.288q3.247 0 5.767-1.512t3.92-4.2q1.456-2.744 1.456-6.216 0-3.528-1.456-6.216-1.4-2.744-3.976-4.256-2.52-1.568-5.824-1.568t-5.88 1.568q-2.52 1.512-3.976 4.256-1.4 2.688-1.4 6.216 0 3.473 1.456 6.216 1.456 2.688 4.032 4.2t5.88 1.512m43.567 8.288q-5.824 0-10.304-2.576-4.425-2.632-7-7.168-2.52-4.591-2.52-10.528 0-5.936 2.52-10.472 2.52-4.591 7-7.168 4.48-2.632 10.304-2.632 4.256 0 7.784 1.512 3.584 1.456 6.16 4.144A18.6 18.6 0 0 1 113.691 23l-8.456 2.856q-1.344-2.911-3.808-4.648-2.465-1.792-5.712-1.792-3.136 0-5.544 1.512-2.352 1.512-3.696 4.256-1.344 2.688-1.344 6.216t1.344 6.272q1.4 2.688 3.808 4.256 2.465 1.512 5.6 1.512 3.416 0 5.712-1.848 2.352-1.848 3.752-4.816l8.456 2.856a19.1 19.1 0 0 1-4.032 6.328q-2.576 2.688-6.16 4.2t-7.896 1.512M115.346 51l15.232-39.2h9.632L155.442 51h-9.52l-2.8-7.448h-15.456l-2.8 7.448zm15.288-15.4h9.52l-4.76-12.712zM159.722 51V11.8h9.016v30.912h16.688V51z"
          />
          <path
            fill="#02ADED"
            d="M268.808 51V11.8h15.4q4.255 0 7.56 1.736 3.304 1.68 5.152 4.704 1.904 2.968 1.904 6.944 0 3.92-1.848 6.944-1.793 3.024-5.04 4.76-3.248 1.736-7.448 1.736h-6.664V51zm9.016-20.664h6.44q2.352 0 3.808-1.4 1.512-1.455 1.512-3.752t-1.568-3.696q-1.568-1.455-4.032-1.456h-6.16zm41.118 21.392q-5.04 0-8.736-1.904-3.64-1.904-5.6-5.376-1.96-3.528-1.96-8.344V11.8h9.016v24.304q0 3.36 1.96 5.32 2.016 1.96 5.432 1.96t5.376-1.96q2.016-1.96 2.016-5.32V11.8h9.016v24.304q0 4.816-2.016 8.344-1.959 3.472-5.712 5.376-3.695 1.904-8.792 1.904M342.527 51V11.8h13.72q6.048 0 9.856 2.8t3.808 8.568a7.74 7.74 0 0 1-1.064 3.92q-1.065 1.792-3.024 3.248 2.631 1.345 4.088 3.64 1.456 2.295 1.456 5.6 0 5.488-3.696 8.456Q364.031 51 358.095 51zm8.792-23.8h4.648q2.127 0 3.416-1.064 1.287-1.12 1.288-3.08 0-2.015-1.4-3.08-1.345-1.12-3.64-1.12h-4.312zm0 15.848h5.768q2.295 0 3.64-1.176 1.344-1.232 1.344-3.136 0-1.96-1.4-3.136-1.401-1.176-3.864-1.176h-5.488zM376.871 51V11.8h9.016v30.912h16.688V51zm30.515 0V11.8h9.016V51zm34.321.672q-5.824 0-10.304-2.576-4.424-2.632-7-7.168-2.52-4.591-2.52-10.528 0-5.936 2.52-10.472 2.52-4.591 7-7.168 4.48-2.632 10.304-2.632 4.256 0 7.784 1.512 3.584 1.456 6.16 4.144A18.6 18.6 0 0 1 459.683 23l-8.456 2.856q-1.344-2.911-3.808-4.648-2.464-1.792-5.712-1.792-3.137 0-5.544 1.512-2.352 1.512-3.696 4.256-1.344 2.688-1.344 6.216t1.344 6.272q1.4 2.688 3.808 4.256 2.464 1.512 5.6 1.512 3.415 0 5.712-1.848 2.352-1.848 3.752-4.816l8.456 2.856a19.1 19.1 0 0 1-4.032 6.328q-2.576 2.688-6.16 4.2t-7.896 1.512"
          />
          <path
            fill="#8DD6F8"
            d="M252.005 25.282c5.082 2.954 4.669 9.859-.735 12.288l-27.277 12.264c-11.717 5.268-25.411-2.533-25.411-14.475V7.069c0-5.567 6.716-8.942 11.852-5.956z"
          />
          <path
            fill="#02ADED"
            d="M234.534 32.79c5.35 2.813 5.102 9.949-.433 12.446l-22.803 10.287c-5.859 2.643-12.716-1.257-12.716-7.233V26.077c0-5.427 6.416-8.823 11.559-6.118z"
          />
          <path
            fill="#2E3091"
            d="M220.206 42.885c3.744 2.055 3.512 7.087-.407 8.847l-10.103 4.537c-5.126 2.302-11.114-1.111-11.114-6.335V39.92c0-3.918 4.672-6.338 8.345-4.322z"
          />
        </svg>
      )
    }
  } else if (variant == 'stacked') {
    if (shade == 'dark') {
      return (
        <svg {...svgProps} viewBox="0 0 224 96">
          {title}
          <path
            fill="#fff"
            d="M4.18 40V1.5h7.535v31.625h16.94V40zm45.84.66q-5.831 0-10.34-2.53-4.456-2.53-6.986-7.04t-2.53-10.285q0-5.885 2.53-10.34 2.53-4.51 6.93-7.04Q44.08.895 49.854.895t10.23 2.53 6.985 7.04 2.53 10.34q0 5.775-2.53 10.285-2.475 4.51-6.93 7.04-4.4 2.53-10.12 2.53m0-6.875q3.464 0 6.16-1.65 2.694-1.706 4.18-4.62 1.54-2.915 1.54-6.71 0-3.85-1.54-6.765-1.542-2.915-4.29-4.565-2.697-1.705-6.216-1.705T43.64 9.475q-2.695 1.65-4.235 4.565t-1.54 6.765q0 3.795 1.54 6.71t4.29 4.62q2.75 1.65 6.325 1.65m42.135 6.875q-5.61 0-9.955-2.53-4.345-2.585-6.82-7.04-2.475-4.51-2.475-10.34t2.42-10.285q2.475-4.51 6.82-7.04Q86.49.84 92.1.84q3.96 0 7.37 1.43 3.41 1.375 5.94 3.905a17.1 17.1 0 0 1 3.96 5.72l-6.985 2.585a12.03 12.03 0 0 0-4.18-4.895q-2.695-1.87-6.105-1.87-3.355 0-5.995 1.705-2.585 1.65-4.07 4.565-1.43 2.915-1.43 6.765t1.485 6.82q1.485 2.915 4.125 4.62 2.64 1.65 6.05 1.65 3.52 0 6.16-1.98t4.07-5.115l6.985 2.53a17.8 17.8 0 0 1-3.96 5.995q-2.53 2.53-5.94 3.96t-7.425 1.43m18.569-.66 15.18-38.5h8.03l15.18 38.5h-7.975l-3.245-8.47h-15.95L118.699 40zm13.75-15.125h10.89l-5.445-14.19zM153.176 40V1.5h7.535v31.625h16.94V40z"
          />
          <path
            fill="#02ADED"
            d="M4.352 95V50.2h17.6q4.865 0 8.64 1.984 3.776 1.92 5.888 5.376 2.175 3.392 2.176 7.936 0 4.48-2.112 7.936-2.047 3.456-5.76 5.44t-8.512 1.984h-7.616V95zm10.304-23.616h7.36q2.688 0 4.352-1.6 1.728-1.665 1.728-4.288t-1.792-4.224q-1.792-1.665-4.608-1.664h-7.04zm46.993 24.448q-5.76 0-9.985-2.176-4.16-2.176-6.4-6.144-2.24-4.032-2.24-9.536V50.2h10.304v27.776q0 3.84 2.24 6.08 2.305 2.24 6.209 2.24t6.144-2.24q2.303-2.24 2.303-6.08V50.2h10.304v27.776q0 5.504-2.303 9.536-2.241 3.968-6.528 6.144-4.225 2.176-10.049 2.176M88.602 95V50.2h15.68q6.912 0 11.264 3.2t4.352 9.792q0 2.368-1.216 4.48-1.216 2.048-3.456 3.712 3.008 1.535 4.672 4.16t1.664 6.4q0 6.272-4.224 9.664Q113.178 95 106.394 95zM98.65 67.8h5.312q2.432 0 3.904-1.216 1.472-1.28 1.472-3.52 0-2.304-1.6-3.52-1.536-1.28-4.16-1.28H98.65zm0 18.112h6.592q2.624 0 4.16-1.344 1.536-1.408 1.536-3.584 0-2.24-1.6-3.584t-4.416-1.344H98.65zM127.852 95V50.2h10.304v35.328h19.072V95zm34.875 0V50.2h10.304V95zm39.224.768q-6.656 0-11.776-2.944-5.056-3.008-8-8.192-2.88-5.249-2.88-12.032t2.88-11.968q2.88-5.249 8-8.192 5.12-3.008 11.776-3.008 4.864 0 8.896 1.728 4.096 1.665 7.04 4.736A21.3 21.3 0 0 1 222.495 63l-9.664 3.264q-1.536-3.329-4.352-5.312-2.816-2.048-6.528-2.048-3.584 0-6.336 1.728-2.688 1.728-4.224 4.864-1.536 3.072-1.536 7.104t1.536 7.168q1.6 3.073 4.352 4.864 2.816 1.728 6.4 1.728 3.905 0 6.528-2.112 2.688-2.112 4.288-5.504l9.664 3.264a21.8 21.8 0 0 1-4.608 7.232q-2.944 3.072-7.04 4.8-4.097 1.728-9.024 1.728"
          />
          <path
            fill="#02ADEE"
            d="M221.553 18.298c3.477 2.022 3.194 6.746-.503 8.408l-18.663 8.391C194.37 38.702 185 33.364 185 25.193V5.836c0-3.809 4.596-6.118 8.11-4.075z"
          />
          <path
            fill="#8DD6F8"
            d="M209.599 23.435c3.661 1.925 3.491 6.807-.296 8.516l-15.602 7.038c-4.009 1.809-8.701-.86-8.701-4.948V18.842c0-3.713 4.391-6.036 7.909-4.185z"
          />
          <path
            fill="#fff"
            d="M199.796 30.343c2.561 1.405 2.403 4.848-.279 6.053l-6.913 3.104c-3.507 1.575-7.604-.76-7.604-4.334v-6.853c0-2.68 3.197-4.336 5.71-2.957z"
          />
        </svg>
      )
    } else {
      return (
        <svg {...svgProps} viewBox="0 0 224 96">
          {title}
          <path
            fill="#2E3091"
            d="M4.18 40V1.5h7.535v31.625h16.94V40zm45.84.66q-5.831 0-10.34-2.53-4.456-2.53-6.986-7.04t-2.53-10.285q0-5.885 2.53-10.34 2.53-4.51 6.93-7.04Q44.08.895 49.854.895t10.23 2.53 6.985 7.04 2.53 10.34q0 5.775-2.53 10.285-2.475 4.51-6.93 7.04-4.4 2.53-10.12 2.53m0-6.875q3.464 0 6.16-1.65 2.694-1.706 4.18-4.62 1.54-2.915 1.54-6.71 0-3.85-1.54-6.765-1.542-2.915-4.29-4.565-2.697-1.705-6.216-1.705T43.64 9.475q-2.695 1.65-4.235 4.565t-1.54 6.765q0 3.795 1.54 6.71t4.29 4.62q2.75 1.65 6.325 1.65m42.135 6.875q-5.61 0-9.955-2.53-4.345-2.585-6.82-7.04-2.475-4.51-2.475-10.34t2.42-10.285q2.475-4.51 6.82-7.04Q86.49.84 92.1.84q3.96 0 7.37 1.43 3.41 1.375 5.94 3.905a17.1 17.1 0 0 1 3.96 5.72l-6.985 2.585a12.03 12.03 0 0 0-4.18-4.895q-2.695-1.87-6.105-1.87-3.355 0-5.995 1.705-2.585 1.65-4.07 4.565-1.43 2.915-1.43 6.765t1.485 6.82q1.485 2.915 4.125 4.62 2.64 1.65 6.05 1.65 3.52 0 6.16-1.98t4.07-5.115l6.985 2.53a17.8 17.8 0 0 1-3.96 5.995q-2.53 2.53-5.94 3.96t-7.425 1.43m18.569-.66 15.18-38.5h8.03l15.18 38.5h-7.975l-3.245-8.47h-15.95L118.699 40zm13.75-15.125h10.89l-5.445-14.19zM153.176 40V1.5h7.535v31.625h16.94V40z"
          />
          <path
            fill="#02ADED"
            d="M4.352 95V50.2h17.6q4.865 0 8.64 1.984 3.776 1.92 5.888 5.376 2.175 3.392 2.176 7.936 0 4.48-2.112 7.936-2.047 3.456-5.76 5.44t-8.512 1.984h-7.616V95zm10.304-23.616h7.36q2.688 0 4.352-1.6 1.728-1.665 1.728-4.288t-1.792-4.224q-1.792-1.665-4.608-1.664h-7.04zm46.993 24.448q-5.76 0-9.985-2.176-4.16-2.176-6.4-6.144-2.24-4.032-2.24-9.536V50.2h10.304v27.776q0 3.84 2.24 6.08 2.305 2.24 6.209 2.24t6.144-2.24q2.303-2.24 2.303-6.08V50.2h10.304v27.776q0 5.504-2.303 9.536-2.241 3.968-6.528 6.144-4.225 2.176-10.049 2.176M88.602 95V50.2h15.68q6.912 0 11.264 3.2t4.352 9.792q0 2.368-1.216 4.48-1.216 2.048-3.456 3.712 3.008 1.535 4.672 4.16t1.664 6.4q0 6.272-4.224 9.664Q113.178 95 106.394 95zM98.65 67.8h5.312q2.432 0 3.904-1.216 1.472-1.28 1.472-3.52 0-2.304-1.6-3.52-1.536-1.28-4.16-1.28H98.65zm0 18.112h6.592q2.624 0 4.16-1.344 1.536-1.408 1.536-3.584 0-2.24-1.6-3.584t-4.416-1.344H98.65zM127.852 95V50.2h10.304v35.328h19.072V95zm34.875 0V50.2h10.304V95zm39.224.768q-6.656 0-11.776-2.944-5.056-3.008-8-8.192-2.88-5.249-2.88-12.032t2.88-11.968q2.88-5.249 8-8.192 5.12-3.008 11.776-3.008 4.864 0 8.896 1.728 4.096 1.665 7.04 4.736A21.3 21.3 0 0 1 222.495 63l-9.664 3.264q-1.536-3.329-4.352-5.312-2.816-2.048-6.528-2.048-3.584 0-6.336 1.728-2.688 1.728-4.224 4.864-1.536 3.072-1.536 7.104t1.536 7.168q1.6 3.073 4.352 4.864 2.816 1.728 6.4 1.728 3.905 0 6.528-2.112 2.688-2.112 4.288-5.504l9.664 3.264a21.8 21.8 0 0 1-4.608 7.232q-2.944 3.072-7.04 4.8-4.097 1.728-9.024 1.728"
          />
          <path
            fill="#8DD6F8"
            d="M221.553 18.298c3.477 2.022 3.194 6.746-.503 8.408l-18.663 8.391C194.37 38.702 185 33.364 185 25.193V5.836c0-3.809 4.596-6.118 8.11-4.075z"
          />
          <path
            fill="#02ADED"
            d="M209.599 23.435c3.661 1.925 3.491 6.807-.296 8.516l-15.602 7.038c-4.009 1.809-8.701-.86-8.701-4.948V18.842c0-3.713 4.391-6.036 7.909-4.185z"
          />
          <path
            fill="#2E3091"
            d="M199.796 30.343c2.561 1.405 2.403 4.848-.279 6.053l-6.913 3.104c-3.507 1.575-7.604-.76-7.604-4.334v-6.853c0-2.68 3.197-4.336 5.71-2.957z"
          />
        </svg>
      )
    }
  } else if (variant == 'stackedAlternative') {
    if (shade == 'dark') {
      return (
        <svg {...svgProps} viewBox="0 0 214 158">
          {title}
          <path
            fill="#fff"
            d="M3.264 105V71.4h7.728v26.496h14.304V105zm39.034.576q-5.184 0-9.168-2.208-3.984-2.256-6.24-6.144-2.256-3.936-2.256-8.976 0-5.088 2.256-9.024t6.192-6.144q3.984-2.208 9.12-2.208t9.072 2.208q3.984 2.208 6.24 6.144t2.256 9.024q0 5.04-2.256 8.976-2.208 3.888-6.144 6.144-3.936 2.208-9.072 2.208m0-7.104q2.784 0 4.944-1.296t3.36-3.6q1.248-2.352 1.248-5.328 0-3.024-1.248-5.328-1.2-2.352-3.408-3.648-2.16-1.344-4.992-1.344t-5.04 1.344q-2.16 1.296-3.408 3.648-1.2 2.304-1.2 5.328 0 2.976 1.248 5.328 1.248 2.304 3.456 3.6t5.04 1.296m34.943 7.104q-4.992 0-8.832-2.208-3.792-2.256-6-6.144-2.16-3.936-2.16-9.024t2.16-8.976q2.16-3.936 6-6.144 3.84-2.256 8.832-2.256 3.648 0 6.672 1.296 3.072 1.248 5.28 3.552A15.9 15.9 0 0 1 92.65 81l-7.248 2.448q-1.152-2.496-3.264-3.984-2.112-1.536-4.896-1.536-2.688 0-4.752 1.296-2.016 1.296-3.168 3.648-1.152 2.304-1.152 5.328t1.152 5.376q1.2 2.304 3.264 3.648 2.112 1.296 4.8 1.296 2.928 0 4.896-1.584 2.016-1.584 3.216-4.128l7.248 2.448a16.4 16.4 0 0 1-3.456 5.424q-2.208 2.304-5.28 3.6t-6.768 1.296M91.668 105l13.056-33.6h8.256l13.056 33.6h-8.16l-2.4-6.384h-13.248l-2.4 6.384zm13.104-13.2h8.16l-4.08-10.896zm22.533 13.2V71.4h7.728v26.496h14.304V105z"
          />
          <path
            fill="#02ADED"
            d="M4.216 157v-43.4h17.05q4.712 0 8.37 1.922 3.658 1.86 5.704 5.208 2.108 3.286 2.108 7.688 0 4.34-2.046 7.688-1.984 3.348-5.58 5.27t-8.246 1.922h-7.378V157zm9.982-22.878h7.13q2.604 0 4.216-1.55 1.674-1.612 1.674-4.154 0-2.543-1.736-4.092-1.736-1.612-4.464-1.612h-6.82zm44.904 23.684q-5.58 0-9.672-2.108-4.03-2.107-6.2-5.952-2.17-3.906-2.17-9.238V113.6h9.982v26.908q0 3.72 2.17 5.89 2.232 2.17 6.014 2.17t5.952-2.17q2.232-2.17 2.232-5.89V113.6h9.982v26.908q0 5.332-2.232 9.238-2.17 3.845-6.324 5.952-4.092 2.108-9.734 2.108M84.593 157v-43.4h15.19q6.696 0 10.912 3.1t4.216 9.486q0 2.294-1.178 4.34-1.177 1.984-3.348 3.596 2.915 1.488 4.526 4.03 1.612 2.542 1.612 6.2 0 6.076-4.092 9.362Q108.402 157 101.829 157zm9.734-26.35h5.146q2.357 0 3.782-1.178 1.426-1.24 1.426-3.41 0-2.232-1.55-3.41-1.488-1.24-4.03-1.24h-4.774zm0 17.546h6.386q2.542 0 4.03-1.302 1.488-1.364 1.488-3.472 0-2.17-1.55-3.472t-4.278-1.302h-6.076zm27.67 8.804v-43.4h9.982v34.224h18.476V157zm33.165 0v-43.4h9.982V157zm37.378.744q-6.448 0-11.408-2.852-4.898-2.914-7.75-7.936-2.79-5.084-2.79-11.656t2.79-11.594q2.79-5.084 7.75-7.936 4.96-2.914 11.408-2.914 4.712 0 8.618 1.674 3.968 1.612 6.82 4.588a20.6 20.6 0 0 1 4.464 6.882l-9.362 3.162q-1.488-3.224-4.216-5.146-2.728-1.984-6.324-1.984-3.472 0-6.138 1.674-2.604 1.674-4.092 4.712-1.488 2.976-1.488 6.882t1.488 6.944q1.55 2.976 4.216 4.712 2.728 1.674 6.2 1.674 3.781 0 6.324-2.046 2.604-2.046 4.154-5.332l9.362 3.162a21.1 21.1 0 0 1-4.464 7.006q-2.852 2.976-6.82 4.65t-8.742 1.674"
          />
          <path
            fill="#02ADE5"
            d="M56.423 25.282c5.082 2.954 4.67 9.859-.735 12.288L28.411 49.834C16.694 55.102 3 47.301 3 35.36V7.069c0-5.567 6.717-8.942 11.853-5.956z"
          />
          <path
            fill="#8DD6F8"
            d="M38.953 32.79c5.35 2.813 5.101 9.949-.434 12.446L15.717 55.523C9.857 58.166 3 54.266 3 48.29V26.077c0-5.427 6.417-8.823 11.56-6.118z"
          />
          <path
            fill="#fff"
            d="M24.624 42.885c3.744 2.055 3.513 7.087-.406 8.847l-10.104 4.537C8.988 58.571 3 55.158 3 49.934V39.92c0-3.918 4.673-6.338 8.345-4.322z"
          />
        </svg>
      )
    } else {
      return (
        <svg {...svgProps} viewBox="0 0 214 158">
          {title}
          <path
            fill="#2E3091"
            d="M3.264 105V71.4h7.728v26.496h14.304V105zm39.034.576q-5.184 0-9.168-2.208-3.984-2.256-6.24-6.144-2.256-3.936-2.256-8.976 0-5.088 2.256-9.024t6.192-6.144q3.984-2.208 9.12-2.208t9.072 2.208q3.984 2.208 6.24 6.144t2.256 9.024q0 5.04-2.256 8.976-2.208 3.888-6.144 6.144-3.936 2.208-9.072 2.208m0-7.104q2.784 0 4.944-1.296t3.36-3.6q1.248-2.352 1.248-5.328 0-3.024-1.248-5.328-1.2-2.352-3.408-3.648-2.16-1.344-4.992-1.344t-5.04 1.344q-2.16 1.296-3.408 3.648-1.2 2.304-1.2 5.328 0 2.976 1.248 5.328 1.248 2.304 3.456 3.6t5.04 1.296m34.943 7.104q-4.992 0-8.832-2.208-3.792-2.256-6-6.144-2.16-3.936-2.16-9.024t2.16-8.976q2.16-3.936 6-6.144 3.84-2.256 8.832-2.256 3.648 0 6.672 1.296 3.072 1.248 5.28 3.552A15.9 15.9 0 0 1 92.65 81l-7.248 2.448q-1.152-2.496-3.264-3.984-2.112-1.536-4.896-1.536-2.688 0-4.752 1.296-2.016 1.296-3.168 3.648-1.152 2.304-1.152 5.328t1.152 5.376q1.2 2.304 3.264 3.648 2.112 1.296 4.8 1.296 2.928 0 4.896-1.584 2.016-1.584 3.216-4.128l7.248 2.448a16.4 16.4 0 0 1-3.456 5.424q-2.208 2.304-5.28 3.6t-6.768 1.296M91.668 105l13.056-33.6h8.256l13.056 33.6h-8.16l-2.4-6.384h-13.248l-2.4 6.384zm13.104-13.2h8.16l-4.08-10.896zm22.533 13.2V71.4h7.728v26.496h14.304V105z"
          />
          <path
            fill="#02ADED"
            d="M4.216 157v-43.4h17.05q4.712 0 8.37 1.922 3.658 1.86 5.704 5.208 2.108 3.286 2.108 7.688 0 4.34-2.046 7.688-1.984 3.348-5.58 5.27t-8.246 1.922h-7.378V157zm9.982-22.878h7.13q2.604 0 4.216-1.55 1.674-1.612 1.674-4.154 0-2.543-1.736-4.092-1.736-1.612-4.464-1.612h-6.82zm44.904 23.684q-5.58 0-9.672-2.108-4.03-2.107-6.2-5.952-2.17-3.906-2.17-9.238V113.6h9.982v26.908q0 3.72 2.17 5.89 2.232 2.17 6.014 2.17t5.952-2.17q2.232-2.17 2.232-5.89V113.6h9.982v26.908q0 5.332-2.232 9.238-2.17 3.845-6.324 5.952-4.092 2.108-9.734 2.108M84.593 157v-43.4h15.19q6.696 0 10.912 3.1t4.216 9.486q0 2.294-1.178 4.34-1.177 1.984-3.348 3.596 2.915 1.488 4.526 4.03 1.612 2.542 1.612 6.2 0 6.076-4.092 9.362Q108.402 157 101.829 157zm9.734-26.35h5.146q2.357 0 3.782-1.178 1.426-1.24 1.426-3.41 0-2.232-1.55-3.41-1.488-1.24-4.03-1.24h-4.774zm0 17.546h6.386q2.542 0 4.03-1.302 1.488-1.364 1.488-3.472 0-2.17-1.55-3.472t-4.278-1.302h-6.076zm27.67 8.804v-43.4h9.982v34.224h18.476V157zm33.165 0v-43.4h9.982V157zm37.378.744q-6.448 0-11.408-2.852-4.898-2.914-7.75-7.936-2.79-5.084-2.79-11.656t2.79-11.594q2.79-5.084 7.75-7.936 4.96-2.914 11.408-2.914 4.712 0 8.618 1.674 3.968 1.612 6.82 4.588a20.6 20.6 0 0 1 4.464 6.882l-9.362 3.162q-1.488-3.224-4.216-5.146-2.728-1.984-6.324-1.984-3.472 0-6.138 1.674-2.604 1.674-4.092 4.712-1.488 2.976-1.488 6.882t1.488 6.944q1.55 2.976 4.216 4.712 2.728 1.674 6.2 1.674 3.781 0 6.324-2.046 2.604-2.046 4.154-5.332l9.362 3.162a21.1 21.1 0 0 1-4.464 7.006q-2.852 2.976-6.82 4.65t-8.742 1.674"
          />
          <path
            fill="#8DD6F8"
            d="M56.423 25.282c5.082 2.954 4.67 9.859-.735 12.288L28.411 49.834C16.694 55.102 3 47.301 3 35.36V7.069c0-5.567 6.717-8.942 11.853-5.956z"
          />
          <path
            fill="#02ADED"
            d="M38.953 32.79c5.35 2.813 5.101 9.949-.434 12.446L15.717 55.523C9.857 58.166 3 54.266 3 48.29V26.077c0-5.427 6.417-8.823 11.56-6.118z"
          />
          <path
            fill="#2E3091"
            d="M24.624 42.885c3.744 2.055 3.513 7.087-.406 8.847l-10.104 4.537C8.988 58.571 3 55.158 3 49.934V39.92c0-3.918 4.673-6.338 8.345-4.322z"
          />
        </svg>
      )
    }
  }
}

export default Logo
