import { useState } from 'react'

import humanizeString from 'humanize-string'
import { BsArrowUpShort } from 'react-icons/bs'
import { FaRegThumbsUp } from 'react-icons/fa'

import { formatDatetime } from 'src/lib/formatters'
import { ImageProfile, getImage, transformImage } from 'src/lib/images'

import { TableHeadingType, ContentItemType } from '../../../common/types'
import Loader from '../Loader/Loader'
import StatusPill from '../StatusPill/StatusPill'

import Pagination, { PaginationConfig } from './Pagination'

interface ContentListingTable {
  contentList: Array<ContentItemType>
  tableHeading: Array<TableHeadingType>
  paginationConfig?: PaginationConfig
  onPageChange?: (toPage: number) => void
  handleSort?: (sortParams: { sortBy: string; toggle: string }) => void
  actionsList?: (itemData: ContentItemType) => React.JSX.Element
  renderer?: {
    [key: string]: (
      itemData: ContentItemType,
      field: string
    ) => React.JSX.Element
  }
  imageProfile?: string
  emptyMessage?: string
  isLoading?: boolean
}

const ContentListingTable = ({
  contentList,
  tableHeading,
  paginationConfig,
  onPageChange,
  handleSort = null,
  actionsList = null,
  renderer = null,
  imageProfile = ImageProfile.PBS_VIDEO_MEZZANINE,
  emptyMessage = 'No results',
  isLoading = false,
}: ContentListingTable) => {
  const [toggle, setToggle] = useState(false)
  const [sortBy, setSortBy] = useState('')

  const onClickHeaderToSort = (event) => {
    event.preventDefault()
    if (event?.target?.value) {
      let updatedToggle = false
      if (sortBy === event?.target?.value) {
        updatedToggle = !toggle
      }
      setToggle(updatedToggle)
      setSortBy(event.target.value)
      // Pass the the value for sort by which column and which val
      handleSort({
        sortBy: event.target.value,
        toggle: updatedToggle ? 'desc' : 'asc',
      })
    }
  }

  return (
    <div className="relative min-h-[300px] overflow-x-auto p-2 text-sm text-[#000]">
      {paginationConfig && (
        <Pagination
          contentList={contentList}
          onPageChange={onPageChange}
          paginationConfig={paginationConfig}
        />
      )}
      {isLoading ? (
        <div className="absolute left-1/2 top-1/2">
          <Loader />
        </div>
      ) : contentList.length === 0 ? (
        <div className="flex w-full items-center justify-center p-[10px] font-semibold md:p-[50px] lg:p-[100px]">
          <div className="w-full bg-gray-100 p-3 text-center md:w-4/5 md:p-8 lg:w-3/4 lg:p-20">
            {emptyMessage}
          </div>
        </div>
      ) : (
        <table className="w-full divide-y divide-gray-300 text-left">
          <thead>
            <tr>
              {tableHeading.map((heading) => (
                <th
                  scope="col"
                  className="p-4 font-bold"
                  key={`${heading.label}-heading-${heading.id}`}
                >
                  {!!(
                    heading.field !== 'actions' ||
                    (heading.field === 'actions' && actionsList)
                  ) && (
                    <div className="flex flex-1 flex-row items-center justify-center">
                      {heading.canSort ? (
                        <button
                          className="text-center"
                          onClick={onClickHeaderToSort}
                          value={heading.field}
                        >
                          {heading.label}
                        </button>
                      ) : (
                        <div className="text-center">{heading.label}</div>
                      )}
                      {heading.canSort && sortBy === heading.field && (
                        <BsArrowUpShort
                          className={
                            toggle && sortBy === heading.field
                              ? 'rotate-180'
                              : ''
                          }
                        />
                      )}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody key={'sasas'} className="divide-y divide-gray-200">
            {contentList.map((itemData) => (
              <tr key={`itemData-${itemData.id}`}>
                {tableHeading.map((heading) => {
                  if (
                    heading?.type === 'renderer' &&
                    renderer &&
                    typeof renderer === 'object' &&
                    Object.keys(renderer).length &&
                    renderer[heading.field]
                  ) {
                    return (
                      <td
                        key={`${heading.id}-obj-${itemData.id}`}
                        className="max-w-[125px] p-2 text-center"
                      >
                        {renderer[heading.field](itemData, heading.field)}
                      </td>
                    )
                  } else if (
                    heading.field === 'image' ||
                    heading.field === 'logo'
                  ) {
                    const imageUrl = itemData.image || (itemData.logo as string)
                    return (
                      <td
                        key={`${heading.id}-img-${itemData.id}`}
                        className="p-2"
                      >
                        <div className="flex justify-center">
                          <img
                            src={transformImage({
                              imageUrl,
                              options: { height: 80 },
                            })}
                            alt="img"
                            className="aspect-auto h-20"
                          />
                        </div>
                      </td>
                    )
                  } else if (heading.field === 'images') {
                    const poster = getImage(itemData.images, imageProfile)
                    return (
                      <td
                        key={`${heading.id}-img-${itemData.id}`}
                        className="p-2"
                      >
                        <div className="flex justify-center">
                          <img
                            src={transformImage({
                              imageUrl: poster.image,
                              options: { height: 80 },
                            })}
                            alt="img"
                            className="aspect-auto h-20"
                          />
                        </div>
                      </td>
                    )
                  } else if (heading.field === 'hasSubtitles') {
                    return (
                      <td
                        key={`${heading.id}-has-sub-${itemData.id}`}
                        className="p-2"
                      >
                        <div className="flex justify-center">
                          {itemData[heading.field] ? 'Yes' : 'No'}
                        </div>
                      </td>
                    )
                  } else if (heading?.type === 'date') {
                    return (
                      <td
                        key={`${heading.id}-date-${itemData.id}`}
                        className="max-w-[125px] p-2 text-center"
                      >
                        {formatDatetime(itemData[heading.field] as string)}
                      </td>
                    )
                  } else if (heading.field === 'actions' && actionsList) {
                    return (
                      <td key={`${heading.id}-act-${itemData.id}`}>
                        {actionsList(itemData)}
                      </td>
                    )
                  } else if (heading.field === 'status') {
                    return (
                      <td
                        key={`${heading.id}-sta-${itemData.id}`}
                        className="max-w-[125px] p-2 text-center"
                      >
                        <StatusPill
                          title={itemData.statusTitle}
                          type={itemData.statusType}
                        />
                      </td>
                    )
                  } else if (heading.field === 'isRecommended') {
                    return (
                      <td
                        key={`${heading.id}-rec-${itemData.id}`}
                        className="max-w-[125px] p-2 text-center"
                      >
                        <div className="inline-block">
                          {itemData[heading.field] ? (
                            <FaRegThumbsUp size={24} />
                          ) : (
                            '-'
                          )}
                        </div>
                      </td>
                    )
                  } else if (heading.field === 'showType') {
                    return (
                      <td
                        key={`${heading.id}-rec-${itemData.id}`}
                        className="max-w-[125px] p-2 text-center"
                      >
                        <div className="inline-block">
                          {humanizeString(itemData['showType'] as string)}
                        </div>
                      </td>
                    )
                  } else if (heading.field === 'videoTotals') {
                    return (
                      <td
                        key={`${heading.id}-rec-${itemData.id}`}
                        className="max-w-[125px] p-2 text-center"
                      >
                        <div className="inline-block">
                          {itemData.videoTotals
                            ? itemData.videoTotals['total']
                            : 0}
                        </div>
                      </td>
                    )
                  } else if (heading.field === 'videoType') {
                    return (
                      <td
                        key={`${heading.id}-rec-${itemData.id}`}
                        className="max-w-[125px] p-2 text-center"
                      >
                        {humanizeString(itemData['videoType'] as string)}
                      </td>
                    )
                  } else if (heading.field === 'publicStart') {
                    return (
                      <td
                        key={`${heading.id}-rec-${itemData.id}`}
                        className="max-w-[125px] p-2 text-center"
                      >
                        {itemData['availabilities']['PUBLIC']?.start ?? '-'}
                      </td>
                    )
                  } else if (heading.field === 'publicEnd') {
                    return (
                      <td
                        key={`${heading.id}-rec-${itemData.id}`}
                        className="max-w-[125px] p-2 text-center"
                      >
                        {itemData['availabilities']['PUBLIC']?.end ?? '-'}
                      </td>
                    )
                  } else if (heading.field === 'passportStart') {
                    return (
                      <td
                        key={`${heading.id}-rec-${itemData.id}`}
                        className="max-w-[125px] p-2 text-center"
                      >
                        {itemData['availabilities']['ALL_MEMBERS']?.start ??
                          '-'}
                      </td>
                    )
                  } else if (heading.field === 'passportEnd') {
                    return (
                      <td
                        key={`${heading.id}-rec-${itemData.id}`}
                        className="max-w-[125px] p-2 text-center"
                      >
                        {itemData['availabilities']['ALL_MEMBERS']?.end ?? '-'}
                      </td>
                    )
                  }
                  // check if heading.field is nested
                  else if (heading?.field.includes('.')) {
                    const nestedFields = heading.field.split('.')
                    let nestedData = itemData
                    nestedFields.forEach((field) => {
                      nestedData = (nestedData as unknown)[field]
                    })
                    return (
                      <td
                        key={`${heading.id}-oth-${itemData.id}`}
                        className="max-w-[125px] p-2 text-center"
                      >
                        {nestedData ? nestedData.toString() : ''}
                      </td>
                    )
                  }
                  return (
                    <td
                      key={`${heading.id}-oth-${itemData.id}`}
                      className="max-w-[125px] p-2 text-center"
                    >
                      {heading?.type === 'links' && itemData?.url ? (
                        <a
                          className="text-indigo-700 underline"
                          href={itemData.url as string}
                        >
                          {itemData[heading.field] as string}
                        </a>
                      ) : (
                        (itemData[heading.field] as string)
                      )}
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {paginationConfig && !isLoading && (
        <Pagination
          contentList={contentList}
          onPageChange={onPageChange}
          paginationConfig={paginationConfig}
        />
      )}
    </div>
  )
}

export default ContentListingTable
