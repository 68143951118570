export const menuData = [
  {
    label: 'Dashboard',
    url: '/',
  },
  {
    label: 'Hero Lineup',
    url: '/heroes/lineup',
  },
  {
    label: 'Carousel Lineup',
    url: '/carousels/lineup',
  },
  {
    label: 'Media Library',
    subMenu: [
      {
        label: 'Shows',
        url: '/shows',
      },
      {
        label: 'Videos',
        url: '/videos',
      },
    ],
  },
  {
    label: 'Marketing',
    subMenu: [
      {
        label: 'Messaging',
        url: '/messaging',
      },
      {
        label: 'Presenting Sponsors',
        url: '/presenting-sponsors',
      },
    ],
  },
  {
    label: 'Station settings',
    subMenu: [
      {
        label: 'General Settings',
        url: '/station',
      },
      {
        label: 'Livestreams',
        url: '/station/livestreams',
      },
      {
        label: 'Users',
        url: '/station/users',
      },
    ],
  },
  {
    label: 'Global Media',
    subMenu: [
      {
        label: 'New Shows',
        url: '/global/shows/added',
      },
      {
        label: 'Shows Missing Metadata',
        url: '/global/shows/missing-metadata',
      },
      {
        label: 'Shows',
        url: '/global/shows',
      },
      {
        label: 'Videos',
        url: '/global/videos',
      },
    ],
  },
  {
    label: 'Global Programming',
    subMenu: [
      {
        label: 'Hero Lineups',
        url: '/global/hero-lineups',
      },
      {
        label: 'Carousel Lineups',
        url: '/global/carousel-lineups',
      },
    ],
  },
  {
    label: 'Global Metadata',
    subMenu: [
      {
        label: 'Genres',
        url: '/global/genres',
      },
      {
        label: 'Vibes',
        url: '/global/vibes',
      },
    ],
  },
  {
    label: 'Global Marketing',
    subMenu: [
      {
        label: 'Messaging',
        url: '/',
      },
    ],
  },
  {
    label: 'Stations',
    url: '/global/stations',
  },
  {
    label: 'Global Users',
    url: '/global/users',
  },
  {
    label: 'Admin',
    subMenu: [
      {
        label: 'API Users',
        url: '/api-users',
      },
      {
        label: 'PBS Account Configurations',
        url: '/pbs-account-configs',
      },
      {
        label: 'Station Credential Sets',
        url: '/station-credential-sets',
      },
      {
        label: 'Webhooks',
        url: '/webhooks',
      },
    ],
  },
]
