import { ContentItemType } from 'src/common/types'

interface PageNumberButtonProps {
  isActive: boolean
  pageNum: number
  onButtonClick: (toPage: number) => void
}

const PageNumberButton = ({
  isActive,
  pageNum,
  onButtonClick,
}: PageNumberButtonProps) => {
  return (
    <button
      onClick={() => onButtonClick(pageNum)}
      className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${isActive ? 'focus-visible:outline-indigo-600" z-10 bg-indigo-600 text-white focus-visible:outline-2 focus-visible:outline-offset-2' : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'}`}
    >
      {pageNum}
    </button>
  )
}

export type PaginationConfig = {
  limit: number
  page: number
  pageCount: number
  totalItems?: number
}

interface PaginationProps {
  contentList: Array<ContentItemType>
  onPageChange?: (toPage: number) => void
  paginationConfig?: PaginationConfig
}

const Pagination = ({
  contentList,
  onPageChange,
  paginationConfig,
}: PaginationProps) => {
  const {
    limit: itemsPerPage,
    page: currentPage,
    pageCount,
    totalItems,
  } = paginationConfig

  const handlePageChange = (toPage: number) => {
    if (
      toPage > 0 &&
      (contentList.length === itemsPerPage || toPage < currentPage)
    ) {
      onPageChange(toPage)
    }
  }

  const makeButton = (pageNum: number) => {
    return (
      <PageNumberButton
        isActive={pageNum === currentPage}
        pageNum={pageNum}
        onButtonClick={handlePageChange}
        key={pageNum}
      />
    )
  }

  const makeEllipsis = (key: string) => (
    <span
      className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
      key={key}
    >
      ...
    </span>
  )

  const getDisplayedPages = () => {
    const pages = []
    const visiblePageCount = 5
    if (pageCount <= visiblePageCount) {
      for (let i = 1; i <= pageCount; i++) {
        pages.push(makeButton(i))
      }
      return pages
    }

    const pagesBefore = Math.floor(visiblePageCount / 2)
    const pagesAfter = visiblePageCount - pagesBefore - 1
    if (currentPage <= pagesBefore + 1) {
      for (let i = 1; i <= visiblePageCount - 1; i++) {
        pages.push(makeButton(i))
      }
      pages.push(makeEllipsis('break'))
      pages.push(makeButton(pageCount))
    } else if (currentPage >= pageCount - pagesAfter) {
      pages.push(makeButton(1))
      pages.push(makeEllipsis('break'))
      for (let i = pageCount - visiblePageCount + 2; i <= pageCount; i++) {
        pages.push(makeButton(i))
      }
    } else {
      pages.push(makeButton(1))
      pages.push(makeEllipsis('break1'))
      for (
        let i = currentPage - pagesBefore;
        i <= currentPage + pagesAfter;
        i++
      ) {
        pages.push(makeButton(i))
      }
      pages.push(makeEllipsis('break2'))
      pages.push(makeButton(pageCount))
    }
    return pages
  }

  const pages = getDisplayedPages()

  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === pageCount
  const firstResultOnPage = itemsPerPage * currentPage - itemsPerPage + 1
  const lastResultOnPage = isLastPage ? totalItems : itemsPerPage * currentPage

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      {totalItems > 0 && (
        <div className="flex flex-1 items-center justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{firstResultOnPage}</span>{' '}
              to <span className="font-medium">{lastResultOnPage}</span> of{' '}
              <span className="font-medium">{totalItems}</span> results
            </p>
          </div>
          <div>
            <nav
              className="shadow-xs isolate inline-flex -space-x-px rounded-md"
              aria-label="Pagination"
            >
              <button
                className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 ${isFirstPage ? `opacity-50` : `hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}`}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <span className="sr-only">Previous</span>
                <svg
                  className="size-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.78 5.22a.75.75 0 0 1 0 1.06L8.06 10l3.72 3.72a.75.75 0 1 1-1.06 1.06l-4.25-4.25a.75.75 0 0 1 0-1.06l4.25-4.25a.75.75 0 0 1 1.06 0Z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              {pages}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 ${isLastPage ? `opacity-50` : `hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}`}
              >
                <span className="sr-only">Next</span>
                <svg
                  className="size-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </nav>
          </div>
        </div>
      )}
    </div>
  )
}

export default Pagination
