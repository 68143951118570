export const GET_SHOWS_WITH_MISSING_METADATA = gql`
  query GetShowsWithMissingMetadata(
    $search: String
    $start: Date
    $end: Date
    $filters: FilterShowInput
  ) {
    missingMetadataShows(
      search: $search
      start: $start
      end: $end
      filters: $filters
    ) {
      normalizedShows {
        createdAt
        earliestFullLengthContentAvailableAt
        id
        images {
          image
          profile
        }
        title
      }
      offset
      total
    }
  }
`

export const GET_NEW_SHOWS = gql`
  query GetNewShows($byDate: DateTime!, $filters: FilterNewShowInput) {
    newShows(byDate: $byDate, filters: $filters) {
      normalizedShows {
        id
        title
        images {
          image
          profile
        }
        createdAt
        updatedAt
      }
      offset
      total
    }
  }
`
