import React, { forwardRef, Ref, useImperativeHandle, useRef } from 'react'

export type TextInputProps = {
  helpText?: string
  helpTextPosition?: 'right' | 'under'
  id?: string
  isRequired?: boolean
  label?: string
  max?: number
  maxLength?: number
  min?: number
  name: string
  onBlur?: (value: string) => void
  onChange?: (value: string) => void
  placeholder?: string
  step?: number
  type: 'text' | 'number' | 'email'
  value?: string
  width?: string
}

// We can use this to make sure that the width prop is one of the options.
type WidthOption = 'small' | '25%' | '33%' | '50%' | 'full'

// This is a map of the width options to the Tailwind classes.
const widthClassMap: Record<WidthOption, string> = {
  small: 'w-12',
  '25%': 'w-1/4',
  '33%': 'w-1/3',
  '50%': 'w-1/2',
  full: 'w-full',
}

export interface InputHandle {
  focus: () => void
}

const TextInputComponent = forwardRef(
  (props: TextInputProps, ref: Ref<InputHandle>) => {
    const {
      helpText,
      helpTextPosition = 'under',
      isRequired = false,
      label,
      max,
      maxLength,
      min,
      name,
      onBlur,
      onChange,
      placeholder,
      type,
      step,
      value,
      width = 'full',
    } = props
    const id = props.id ?? `input-${name}`

    const inputRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current?.focus()
      },
    }))

    return (
      <div className="flex flex-col flex-wrap">
        <label
          htmlFor={id}
          className={`${
            !label ? 'sr-only' : 'mb-2 block w-full font-medium text-gray-700'
          }`}
          aria-label={!label ? 'input field' : undefined}
        >
          {label} {isRequired && <span className="text-red-500">*</span>}
        </label>
        <div
          className={`flex w-full ${
            helpTextPosition === 'under' ? 'flex-wrap' : ''
          }`}
        >
          <input
            ref={inputRef}
            type={type}
            name={name}
            value={value ?? ''}
            max={max}
            maxLength={maxLength}
            min={min}
            step={step}
            onChange={(e) => onChange(e.target.value)}
            onBlur={(e) => onBlur && onBlur(e.target.value)}
            id={id}
            placeholder={placeholder}
            aria-label={!label ? name : undefined}
            className={`focus:shadow-outline appearance-none rounded border border-gray-300 px-0 pl-2 leading-tight text-gray-900 focus:outline-none ${widthClassMap[width]}`}
            {...(isRequired ? { required: true } : {})}
          />
          {helpText && helpTextPosition === 'right' && (
            <small className="ml-2 block self-center text-gray-500">
              {helpText}
            </small>
          )}
        </div>
        {helpText && helpTextPosition === 'under' && (
          <small className="mt-2 block text-gray-500">{helpText}</small>
        )}
      </div>
    )
  }
)

export default TextInputComponent
