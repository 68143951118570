import { useState } from 'react'

import {
  FieldError,
  Form,
  Label,
  Submit,
  TextAreaField,
  TextField,
  useForm,
} from '@redwoodjs/forms'

import useWarnUnsavedChanges from 'src/lib/hooks/useWarnUnsavedChanges'

import { ContactAddressType } from '../../../common/types'
import Button from '../Button/Button'
import Loader from '../Loader/Loader'

interface UpdatableTextProps {
  value?: string
  addressValue?: ContactAddressType
  textArea?: boolean
  onChange?: (value: string | null, key?: string) => void
  onSave?: () => Promise<void>
  validationRules?: {
    required?: string
    validate?: {
      [key: string]: (value: string) => boolean | string
    }
  }
  loadingData: boolean
}

const UpdatableText = ({
  value,
  addressValue,
  textArea = false,
  onChange,
  onSave,
  validationRules,
  loadingData,
}: UpdatableTextProps) => {
  const [editable, setEditable] = useState(false)
  const [originalValues, setOriginalValues] = useState(null)
  const formMethods = useForm()

  const setUnsaved = useWarnUnsavedChanges()

  const toggleEditable = () => {
    if (!editable) {
      setOriginalValues({ value, addressValue })
    }
    setEditable(!editable)
  }

  const handleChange = (value: string | null, key: string | null = null) => {
    setUnsaved(true)
    onChange(value, key)
  }

  const handleCancel = () => {
    setEditable(false)
    onChange(originalValues.value)
    if (originalValues.addressValue) {
      for (const key in originalValues.addressValue) {
        onChange(originalValues.addressValue[key], key)
      }
    }

    setUnsaved(false)

    formMethods.reset()
  }

  const onFormSubmit = () => {
    onSave()
    setUnsaved(false)
    setEditable(false)
  }

  return (
    <Form formMethods={formMethods} onSubmit={onFormSubmit}>
      {editable ? (
        <div className="flex">
          {typeof addressValue !== 'undefined' && (
            <div className="flex w-full flex-grow flex-col">
              <Label
                name="Street"
                className="block text-sm font-medium text-gray-700"
              />
              <TextField
                className="my-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                name="addressStreet"
                defaultValue={addressValue.addressStreet}
                validation={{ required: 'Please provide an address.' }}
                onChange={(e) =>
                  handleChange(e.target.value || null, 'addressStreet')
                }
              />
              <FieldError className="text-red-700" name="addressStreet" />

              <Label
                name="City"
                className="block text-sm font-medium text-gray-700"
              />
              <TextField
                className="my-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                name="addressCity"
                defaultValue={addressValue.addressCity}
                validation={{ required: 'Please provide a city.' }}
                onChange={(e) =>
                  handleChange(e.target.value || null, 'addressCity')
                }
              />
              <FieldError className="text-red-700" name="addressCity" />

              <Label
                name="State"
                className="block text-sm font-medium text-gray-700"
              />
              <TextField
                className="my-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                name="addressState"
                defaultValue={addressValue.addressState}
                validation={{
                  required:
                    'Please provide the 2 letter abbreviation for the state.',
                  pattern: {
                    value: /^[A-Za-z]{2}$/,
                    message:
                      'Please provide the 2 letter abbreviation for the state.',
                  },
                }}
                onChange={(e) =>
                  handleChange(e.target.value || null, 'addressState')
                }
              />
              <FieldError className="text-red-700" name="addressState" />

              <Label
                name="Zip Code"
                className="block text-sm font-medium text-gray-700"
              />
              <TextField
                className="my-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                name="addressZip"
                defaultValue={addressValue.addressZip}
                validation={{
                  required: 'Please enter a 5-digit zip code.',
                  validate: {
                    length: (value) =>
                      value.length === 5 || 'Zip code must be 5 digits long.',
                    numeric: (value) =>
                      /^\d+$/.test(value) ||
                      'Zip code must only contain numbers.',
                  },
                }}
                onChange={(e) =>
                  handleChange(e.target.value || null, 'addressZip')
                }
              />
              <FieldError className="text-red-700" name="addressZip" />
            </div>
          )}

          {textArea && (
            <div className="w-full">
              <TextAreaField
                name="value"
                defaultValue={value}
                validation={validationRules}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => handleChange(e.target.value || null)}
              />
              <FieldError name="value" className="text-red-700" />
            </div>
          )}
          {textArea === false && (
            <div className="w-full">
              <TextField
                name="value"
                defaultValue={value}
                validation={validationRules}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => handleChange(e.target.value || null)}
              />
              <FieldError name="value" className="text-red-700" />
            </div>
          )}
          <div className="flex flex-col justify-center">
            <Submit disabled={loadingData} className="border-0 text-indigo-700">
              {loadingData ? <Loader /> : 'Save'}
            </Submit>

            <Button
              title="Cancel"
              customButtonClass="border-0 text-indigo-700"
              onClick={handleCancel}
            />
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-between">
          {value ? (
            <p>{value}</p>
          ) : addressValue ? (
            <p>
              {addressValue.addressStreet || <em>Not set</em>}{' '}
              {addressValue.addressCity || <em>Not set</em>},{' '}
              {addressValue.addressState || <em>Not set</em>}{' '}
              {addressValue.addressZip || <em>Not set</em>}
            </p>
          ) : (
            <p>
              <em>Not set</em>
            </p>
          )}
          <Button
            title="Update"
            customButtonClass="border-0 text-indigo-700"
            onClick={toggleEditable}
          />
        </div>
      )}
    </Form>
  )
}

export default UpdatableText
