import { UserRole } from 'src/lib/enums'

export const routeAccessControl = {
  [UserRole.ADMIN]: [
    '/api-users',
    '/global/carousel-lineups',
    '/global/carousels',
    '/global/genres',
    '/global/hero-lineups',
    '/global/hero-lineups/new',
    '/global/heroes',
    '/global/shows',
    '/global/shows/added',
    '/global/shows/missing-metadata',
    '/global/stations',
    '/global/users',
    '/global/vibes',
    '/global/videos',
    '/heroes',
    '/pbs-account-configs',
    '/station-credential-sets',
    '/stations',
    '/webhooks',
  ],
  [UserRole.GLOBAL_GM]: ['/global/stations', '/global/users'],
  [UserRole.GLOBAL_PROGRAMMER]: [
    '/global/carousel-lineups',
    '/global/carousels',
    '/global/genres',
    '/global/hero-lineups',
    '/global/hero-lineups/new',
    '/global/heroes',
    '/global/shows',
    '/global/shows/added',
    '/global/shows/missing-metadata',
    '/global/vibes',
    '/global/videos',
    '/heroes',
  ],
  [UserRole.STATION_GM]: ['/station', '/station/livestreams', '/station/users'],
  [UserRole.STATION_MARKETER]: [
    '/messaging',
    '/presenting-sponsors',
    '/promos',
  ],
  [UserRole.STATION_PROGRAMMER]: [
    '/carousels/lineup',
    '/heroes/lineup',
    '/shows',
    '/videos',
  ],
}
