import { useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { ImageProfile, UrlWithProfile } from 'api/src/lib/image'
import { BiLogIn, BiLogOut } from 'react-icons/bi'
import './Sidebar.css'
import { GetStationBranding } from 'types/graphql'

import { Link, NavLink, navigate, routes, useLocation } from '@redwoodjs/router'

import { RightArrow } from 'src/assets/RightArrow'
import { useAuth } from 'src/auth'
import { GET_STATION_BRANDING } from 'src/common/queries'
import Button from 'src/components/Custom/Button/Button'
import { transformImage } from 'src/lib/images'

import HeadingLevel from '../Heading/Heading'
import Logo from '../Logo/Logo'

import { menuData } from './menuData'
import { routeAccessControl } from './routeAccessControl'

interface SideBarItem {
  item: {
    url?: string
    label: string
    subMenu?: Array<{
      url: string
      label: string
    }>
  }
  itemId: string
  location: string
  openSubMenuId?: Array<string>
  toggleSubMenu?: (id: string) => void
}

const sidebarItem = ({
  item,
  itemId,
  location,
  openSubMenuId = [],
  toggleSubMenu,
}: SideBarItem) => {
  return (
    <ul key={`ul-${itemId}`}>
      <li
        className={`flex rounded-lg pr-2 ${
          location === item?.url?.split('/').at(-1)
            ? 'bg-white'
            : 'hover:bg-zinc-100'
        }`}
        key={`li-${itemId}`}
      >
        {item?.subMenu?.length ? (
          <button
            onClick={() => toggleSubMenu && toggleSubMenu(itemId)}
            className="flex w-full items-center rounded-lg p-2"
            aria-label={`${openSubMenuId.includes(itemId) ? 'Hide' : 'Show'} ${
              item.label
            } sub items`}
          >
            <span className="ml-2">{item.label}</span>
            <RightArrow
              classStyle={`ml-auto ${
                openSubMenuId.includes(itemId) ? 'rotate-90' : ''
              }`}
            />
          </button>
        ) : (
          <NavLink
            activeClassName={`ml-2 w-full font-bold bg-gray-100 items-center rounded-lg p-2`}
            className={`ml-2 w-full items-center rounded-lg p-2`}
            to={item.url}
          >
            <div className="flex items-center">
              <span>{item.label}</span>
            </div>
          </NavLink>
        )}
      </li>
    </ul>
  )
}

const Sidebar = () => {
  const { currentUser, isAuthenticated, loading, logOut } = useAuth()
  const location = useLocation().pathname.split('/').at(-1)

  const [filteredMenuData, setFilteredMenuData] = useState([])
  const [openSubMenuId, setOpenSubMenuId] = useState([])

  const stationCallSign = currentUser?.station?.callSign
  const [GetStationBranding, { data: stationData }] =
    useLazyQuery<GetStationBranding>(GET_STATION_BRANDING)

  useEffect(() => {
    if (loading) return

    const canAccess = (pathName: string) => {
      return currentUser?.roles.some((role: string) =>
        routeAccessControl[role]?.includes(pathName)
      )
    }

    setFilteredMenuData(
      menuData
        .map((section) => {
          if (section?.url) {
            return canAccess(section.url) || section.label === 'Dashboard'
              ? section
              : null
          }

          if (section.subMenu) {
            const filteredSubMenu = section.subMenu.filter((subItem) =>
              canAccess(subItem.url)
            )
            if (filteredSubMenu.length === 0) {
              return null
            }
            return {
              ...section,
              subMenu: filteredSubMenu,
            }
          }
          return section
        })
        .filter(Boolean)
    )
  }, [currentUser, loading])

  useEffect(() => {
    if (stationCallSign) {
      GetStationBranding({
        variables: { callSign: stationCallSign },
      })
    }
  }, [GetStationBranding, stationCallSign])
  const logoImage = stationData?.station?.images?.find(
    (image: UrlWithProfile) =>
      image.profile === ImageProfile.STATION_LOGO_PRIMARY
  ) as UrlWithProfile

  // Initialize openSubMenuId with the IDs of the parent items that have the current location in their submenu
  useEffect(() => {
    setOpenSubMenuId([
      filteredMenuData
        .findIndex((menuItem) =>
          menuItem.subMenu?.some(
            (subItem) => subItem?.url?.split('/').at(-1) === location
          )
        )
        .toString(),
    ])
  }, [filteredMenuData, location])

  const toggleSubMenu = (id: string) => {
    if (openSubMenuId.includes(id)) {
      // If the ID is already in the array, check if the submenu of the clicked item contains the current location
      const menuItem = filteredMenuData.find(
        (menuItem, index) => index.toString() == id
      )
      if (
        menuItem?.subMenu?.some(
          (subItem) => subItem?.url?.split('/').at(-1) === location
        )
      ) {
        // If it does, don't close the submenu
        return
      }
      // Otherwise, remove it to close the submenu
      setOpenSubMenuId(openSubMenuId.filter((submenuId) => submenuId !== id))
    } else {
      // If the ID is not in the array, add it to open the submenu
      setOpenSubMenuId([...openSubMenuId, id])
    }
  }

  const userName = (currentUser?.nickname ||
    currentUser?.name ||
    currentUser?.email ||
    '') as string

  return (
    <aside className="flex h-screen w-64 flex-1 flex-col justify-between bg-white text-[14px] text-[#4B5563] shadow-lg">
      <div className="sticky top-0 w-64 bg-white p-4">
        <Link to={routes.home()} aria-label="Home page">
          {logoImage ? (
            <img
              src={transformImage({
                imageUrl: logoImage.url,
                options: { width: 256, background: '#d3d3d3' },
              })}
              alt="Station logo"
              className="max-h-20 w-full"
            />
          ) : stationData?.station?.name ? (
            <HeadingLevel level={1}>{stationData.station.name}</HeadingLevel>
          ) : (
            <Logo />
          )}
        </Link>
      </div>
      <div className="scrollNone grow overflow-y-auto">
        <ul
          className="my-1 cursor-pointer font-medium"
          style={{ padding: '0px 8px' }}
        >
          {filteredMenuData?.map((menuItem, index) => (
            <li key={`${menuItem}-item-${index}`} className="my-1">
              {sidebarItem({
                item: menuItem,
                itemId: index.toString(),
                location,
                openSubMenuId,
                toggleSubMenu,
              })}

              <ul
                className={`submenu-container mt-2 cursor-pointer space-y-1 pb-1 ${
                  openSubMenuId.includes(index.toString()) ? 'open' : 'close'
                }`}
                style={{ marginLeft: '16px' }}
              >
                {menuItem?.subMenu?.length &&
                  menuItem.subMenu.map((subMenuItem, subindex) => (
                    <li key={`${index}-subitem-${subindex}`}>
                      {sidebarItem({
                        item: subMenuItem,
                        itemId: `${index}-${subindex}`,
                        location,
                      })}
                    </li>
                  ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
      <div className="w-64 space-y-2 border-t-2 border-[#F3F4F6] bg-white py-2 text-[16px] font-[500] text-[#6B7280]">
        {!loading && isAuthenticated ? (
          <>
            <div className="mt-2 flex items-center p-2">
              {currentUser?.pictureUrl ? (
                <img
                  src={currentUser.pictureUrl as string}
                  alt="User"
                  className="h-8 w-8 rounded-full"
                />
              ) : null}
              <div className="ml-2 break-all">{userName}</div>
            </div>
            <div className="flex items-center p-2 pb-5">
              <div className="item-center flex h-8 w-8 p-1">
                {isAuthenticated ? (
                  <BiLogOut size={24} />
                ) : (
                  <BiLogIn size={24} />
                )}
              </div>
              <div className="ml-2">
                <Button
                  title={isAuthenticated ? 'Log out' : 'Log in'}
                  onClick={async () => {
                    if (isAuthenticated) {
                      await logOut()
                    } else {
                      navigate(routes.login())
                    }
                  }}
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
    </aside>
  )
}

export default Sidebar
