export type ImageWithProfile = {
  image: string
  profile: ImageProfile
}

/**
 * Image profiles.
 *
 * @see web/src/lib/images
 */
export enum ImageProfile {
  HERO_MEZZANINE = 'hero-mezzanine',
  HERO_MOBILE = 'hero-mobile',
  PBS_SHOW_BACKGROUND = 'background',
  PBS_SHOW_MEZZANINE = 'show-mezzanine16x9',
  PBS_SHOW_MEZZANINE_KIDS = 'show-kids-16x9',
  PBS_SHOW_POSTER = 'show-poster2x3',
  PBS_VIDEO_MEZZANINE = 'asset-mezzanine-16x9',
  PBS_VIDEO_MEZZANINE_KIDS = 'asset-kids-mezzanine1-16x9',
  SHOW_FEATURED_VIDEO_MEZZANINE = 'show-featured-video-mezzanine-16x9',
  STATION_BACKGROUND = 'station-background',
  STATION_COLOR_LOGO = 'station-color-logo',
  STATION_LOGO_PRIMARY = 'station-logo-primary',
  STATION_LOGO_SECONDARY = 'station-logo-secondary',
  STATION_PASSPORT_LOGO = 'station-passport-logo',
  STATION_WHITE_LOGO = 'station-white-logo',
}

export type TransformImageOptions = {
  // Any CSS color code for transparent image background fill (Cloudflare only).
  background?: string
  // Percentage to blur the image.
  blur?: number
  format?: string
  height?: number
  // Image quality percentage (1 - 100).
  quality?: number
  width?: number
}

/**
 * Transforms images with support for Cloudflare and PBS ITS image hosting.
 *
 * Transform options keys are mapped directly to Cloudflare transform URL parameters.
 * PBS ITS keys are converted where supported.
 *
 * @see web/src/lib/images
 * @see https://developers.cloudflare.com/images/transform-images/transform-via-url/
 * @see https://docs.pbs.org/space/ITS/4423682/Image+Transformation+Service+(ITS)
 */
export const transformImage = ({
  imageUrl,
  options,
}: {
  imageUrl: string
  options: TransformImageOptions
}) => {
  if (imageUrl.startsWith(process.env.CLOUDFLARE_IMAGES_URL)) {
    // Remove any trailing slashes from the URL to prevent creating invalid URLs
    // with multiple slashes.
    imageUrl = imageUrl.replace(/\/$/, '')
    const cloudflareOptions = []
    for (const [key, value] of Object.entries(options)) {
      if (value) {
        cloudflareOptions.push(`${key}=${encodeURIComponent(value)}`)
      }
    }
    imageUrl += `/${cloudflareOptions.join(',')}`
  } else if (imageUrl.startsWith('https://image.pbs.org/')) {
    const { blur, format, height, quality, width } = options
    const imageUrlWithTransforms = new URL(imageUrl)
    if (blur) {
      imageUrlWithTransforms.searchParams.append('blur', String(blur))
    }
    if (format) {
      imageUrlWithTransforms.searchParams.append('format', format)
    }
    if (height || width) {
      imageUrlWithTransforms.searchParams.append(
        'resize',
        `${width ? width : ''}x${height ? height : ''}`
      )
    }
    if (quality) {
      imageUrlWithTransforms.searchParams.append('quality', String(quality))
    }
    imageUrl = imageUrlWithTransforms.toString()
  }
  return imageUrl
}

export type UrlWithProfile = {
  url: string
  profile: ImageProfile
}
