import {
  useCallback,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from 'react'

import dayjs from 'dayjs'
import { GetShowsWithMissingMetadata, ImageWithProfile } from 'types/graphql'
import { useDebouncedCallback } from 'use-debounce'

import { DateField, FormProvider, useForm } from '@redwoodjs/forms'
import { navigate, routes } from '@redwoodjs/router'
import { Metadata, useQuery } from '@redwoodjs/web'

import Button from 'src/components/Custom/Button/Button'
import Checkbox from 'src/components/Custom/Checkbox/Checkbox'
import ContentListingTable from 'src/components/Custom/ContentListingTable/ContentListingTable'
import HeadingLevel from 'src/components/Custom/Heading/Heading'
import TextInputComponent, {
  InputHandle,
} from 'src/components/Custom/TextInput/TextInput'
import { formatDateValue } from 'src/lib/formatters'
import useOutsideClick from 'src/lib/hooks/useOutsideClick'
import { getImage } from 'src/lib/images'

import { GET_SHOWS_WITH_MISSING_METADATA } from '../queries'

import { missingMetadataHeader } from './constants'

/**
 * DateRangeFields component.
 */
const DateRangeFields = ({
  startDate,
  endDate,
  onChange,
}): React.JSX.Element => {
  return (
    <div className="flex gap-4">
      <div className="flex-1">
        <HeadingLevel level={3} levelStyle={4} className="mb-2">
          Start Date
        </HeadingLevel>
        <DateField
          onChange={(e) => onChange('start', e.target.value)}
          name="startDate"
          value={startDate ? formatDateValue(startDate) : ''}
          className="date-picker-input w-full rounded-md border border-gray-300 py-2 pl-8"
        />
      </div>

      <div className="flex-1">
        <HeadingLevel level={3} levelStyle={4} className="mb-2">
          End Date
        </HeadingLevel>
        <DateField
          onChange={(e) => onChange('end', e.target.value)}
          name="endDate"
          value={endDate ? formatDateValue(endDate) : ''}
          className="date-picker-input w-full rounded-md border border-gray-300 py-2 pl-8"
        />
      </div>
    </div>
  )
}

interface MissingMetadataFiltersState {
  hasAvailableFullLengthContent?: boolean
  limit?: number
  skip?: number
  sortBy?: { [key: string]: string }
}

interface MissingMetadataRouteParams {
  end?: string
  hasAvailableFullLengthContent?: 'true' | 'undefined'
  page?: number
  search?: string
  sortBy?: string
  sortOrder?: string
  start?: string
}

const MissingMetadatListing = (params: MissingMetadataRouteParams) => {
  const {
    end = undefined,
    hasAvailableFullLengthContent = 'true',
    page = 1,
    search: searchTermFromParams = '',
    sortBy = 'createdAt',
    sortOrder = 'desc',
    start = undefined,
  } = params
  const calculatedPage = typeof page === 'string' ? parseInt(page, 10) : page

  const [currentPage, setCurrentPage] = useState(calculatedPage)
  const [dates, setDates] = useState<{ start?: Date; end?: Date }>({
    start: null,
    end: new Date(), // Default end date = today.
  })
  const [shows, setShows] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [search, setSearch] = useState(searchTermFromParams)
  // Defered search value allows deferred rerendering when fetching search results.
  const deferredSearch = useDeferredValue(search)
  const [searchString, setSearchString] = useState(searchTermFromParams)
  const [searchParams, setSearchParams] = useState<MissingMetadataRouteParams>({
    end,
    hasAvailableFullLengthContent,
    page,
    search: searchTermFromParams,
    start,
  })

  const resultsPerPage = 25
  const [filters, setFilters] = useState<MissingMetadataFiltersState>({
    hasAvailableFullLengthContent:
      hasAvailableFullLengthContent === 'true' || undefined,
    limit: resultsPerPage,
    skip: resultsPerPage * (currentPage - 1),
    sortBy: {
      value: sortBy,
      sort: sortOrder,
    },
  })

  // Ensure user focus remains on search input while actively searching.
  const searchInputRef = useRef<InputHandle>(null)
  const [isSearching, setIsSearching] = useState(false)
  if (isSearching) searchInputRef.current?.focus()

  useOutsideClick(searchInputRef, () => setIsSearching(false))

  const methods = useForm()
  const { loading } = useQuery<GetShowsWithMissingMetadata>(
    GET_SHOWS_WITH_MISSING_METADATA,
    {
      variables: {
        search: deferredSearch,
        start: dates?.start
          ? dayjs(dates.start).format('YYYY-MM-DD')
          : undefined,
        end: dates?.end ? dayjs(dates.end).format('YYYY-MM-DD') : undefined,
        filters: {
          ...filters,
          sortBy: {
            [filters.sortBy.value]: filters.sortBy.sort,
          },
        },
      },
      onCompleted: ({ missingMetadataShows: data }) => {
        setTotalItems(data?.total)
        if (data?.normalizedShows) {
          setShows(
            data.normalizedShows.map((show) => ({
              ...show,
              image: getImage(show.images as Array<ImageWithProfile>).image,
              emptyFields: 'GenreVibes',
            }))
          )
        }
      },
      onError: () => {
        setTotalItems(0)
        setPageCount(0)
      },
    }
  )

  useEffect(() => {
    setPageCount(Math.ceil(totalItems / filters.limit))
  }, [filters.limit, totalItems])

  // Build filters object based on updated search filters or route params.
  const formatFilters = useCallback(
    (params: MissingMetadataRouteParams): MissingMetadataFiltersState => {
      const newFilters: MissingMetadataFiltersState = {}
      // Ensure hasAvailableFullLengthContent filter is boolean or undefined.
      const haflc = 'hasAvailableFullLengthContent'
      if (Object.prototype.hasOwnProperty.call(params, haflc)) {
        newFilters[haflc] = params[haflc] === 'true' || undefined
      }

      // Add sortBy filters based on sort params.
      if (params.sortBy && params.sortOrder)
        newFilters.sortBy = {
          value: params.sortBy,
          sort: params.sortOrder,
        }
      return newFilters
    },
    []
  )

  // Listen for changes to route params and update filters, dates.
  useEffect(() => {
    // Set dates if start/end are found in route params.
    const dateKeys = ['start', 'end']
    dateKeys.forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        setDates((old) => ({ ...old, [key]: params[key] }))
      }
    })
    const updatedFilters = formatFilters(params)
    const toPage = params.page ? Number(params.page) : 1
    setCurrentPage(toPage)
    setFilters((old) => ({
      ...old,
      ...updatedFilters,
      skip: old.limit * (toPage - 1),
    }))
  }, [formatFilters, params])

  // When searchParams are updated, update URL in browser.
  useEffect(() => {
    navigate(routes.globalMetaDataManagerListing({ ...searchParams }), {
      replace: true,
    })
  }, [searchParams])

  const updateSearch = useCallback(
    (params: MissingMetadataRouteParams) => {
      const newFilters = formatFilters(params)
      setFilters((old) => ({ ...old, ...newFilters, skip: 0 }))
      setCurrentPage(1)
      setSearchParams((old) => ({
        ...old,
        ...params,
        page: 1,
      }))
    },
    [formatFilters]
  )

  const updateDates = ({ key, value }: { key: string; value: string }) => {
    setDates((old) => ({
      ...old,
      [key]: value,
    }))
    const newParams: MissingMetadataRouteParams = {}
    newParams[key] = value
    setCurrentPage(1)
    setSearchParams((old) => ({
      ...old,
      ...newParams,
      page: 1,
    }))
  }

  const debouncedHandleSearchChange = useDebouncedCallback((input: string) => {
    setSearch(input)
    updateSearch({ search: input })
  }, 1000)

  const handlePageChange = (toPage: number) => {
    setFilters((old) => ({ ...old, skip: old.limit * (toPage - 1) }))
    setCurrentPage(toPage)
    const newSearchParams = { ...searchParams, page: toPage }
    navigate(routes.globalMetaDataManagerListing(newSearchParams), {
      replace: false,
    })
  }

  const actionsList = (itemData) => {
    return (
      <div className="flex flex-col p-2">
        <Button
          title="Edit"
          onClick={() => navigate(routes.editGlobalShow({ id: itemData.id }))}
          backgroundColor="bg-blue-600"
          customButtonClass="mb-2"
        />
      </div>
    )
  }

  return (
    <div className="w-full p-4">
      <Metadata title="Shows Missing Metadata" />
      <HeadingLevel level={1}>Shows Missing Metadata</HeadingLevel>
      <FormProvider {...methods}>
        <div className="mt-5">
          <div>
            PBS genres are not editable. However, admins may override the Genre
            on each Show (which feeds down to child Videos).
          </div>
          <div className="mt-8 w-full">
            <span className="block pb-2">Search by Show Title</span>
            <TextInputComponent
              ref={searchInputRef}
              type="text"
              name="contentLibrarySearch"
              placeholder="Show title"
              width="full"
              value={searchString}
              onChange={(input) => {
                setIsSearching(true)
                setSearchString(input)
                debouncedHandleSearchChange(input)
              }}
            />
          </div>
          <div className="flex items-center pb-4 pt-6">
            <div>
              <DateRangeFields
                startDate={dates.start}
                endDate={dates.end}
                onChange={(key: string, value: string) =>
                  updateDates({ key, value })
                }
              />
              <Checkbox
                id="exclude-shows-without-full-length-content"
                title="Exclude shows without full-length content"
                onValueChange={(updatedValue) => {
                  updateSearch({
                    hasAvailableFullLengthContent: updatedValue
                      ? 'true'
                      : 'undefined',
                  })
                }}
                defaultValue={filters.hasAvailableFullLengthContent}
                checkboxContainerClass="pt-4"
              />
            </div>
          </div>

          <ContentListingTable
            contentList={shows}
            tableHeading={missingMetadataHeader}
            handleSort={({ sortBy, toggle }) => {
              updateSearch({
                sortOrder: toggle.toLowerCase(),
                sortBy,
              })
            }}
            actionsList={actionsList}
            paginationConfig={{
              limit: filters.limit,
              page: currentPage,
              pageCount,
              totalItems,
            }}
            onPageChange={handlePageChange}
            isLoading={loading}
          />
        </div>
      </FormProvider>
    </div>
  )
}

export default MissingMetadatListing
