import React from 'react'

export interface ErrorMessageProps {
  title?: string
  subtitle?: React.ReactNode
  children?: React.ReactNode
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ title, subtitle }) => {
  return (
    <div className="w-full p-4">
      <h1 className="mb-4 text-xl font-semibold md:text-3xl">{title}</h1>
      {subtitle}
    </div>
  )
}

export default ErrorMessage
