import { useEffect, useState } from 'react'

import { GetAllUsers } from 'types/graphql'

import { Metadata, useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Card from 'src/components/Custom/Card'
import HeadingLevel from 'src/components/Custom/Heading/Heading'
import {
  CREATE_STATION_USER,
  GET_ALL_USERS,
  DEACTIVATE_USER,
  UPDATE_STATION_USER,
} from 'src/components/Custom/UsersEditor/queries'
import UsersEditor, {
  UsersEditorData,
} from 'src/components/Custom/UsersEditor/UsersEditor'
import useWarnUnsavedChanges from 'src/lib/hooks/useWarnUnsavedChanges'

interface GlobalUserState {
  users: UsersEditorData
}

const GlobalUsersPage = () => {
  const [state, setState] = useState<GlobalUserState>(null)
  const [showNewUser, setShowNewUser] = useState<boolean>(false)

  const setUnsaved = useWarnUnsavedChanges()

  const {
    data: userList,
    refetch,
    loading: loadingUsers,
  } = useQuery<GetAllUsers>(GET_ALL_USERS)

  const [deactivateUser, { loading: loadingDeactivateUser }] = useMutation(
    DEACTIVATE_USER,
    {
      onCompleted: () => {
        toast.success('User deactivated successfully')
        refetch()
      },
      onError: () => {
        toast.error('Error deactivating user')
      },
    }
  )

  const [updateUser, { loading: loadingUpdateUser }] = useMutation(
    UPDATE_STATION_USER,
    {
      onCompleted: () => {
        toast.success('User updated successfully')
        refetch()
      },
      onError: (error) => {
        toast.error(`Update failed: ${error?.message}`)
      },
    }
  )

  const [createUser, { loading: loadingCreateUser }] = useMutation(
    CREATE_STATION_USER,
    {
      onCompleted: () => {
        toast.success('User created successfully')
        refetch()
      },
      onError: (error) => {
        toast.error(`Create failed: ${error?.message}`)
      },
    }
  )

  useEffect(() => {
    if (userList && userList.users) {
      const newState = {
        users: {
          users: userList.users
            .filter((user) =>
              user.roles.some((role) => role.startsWith('GLOBAL'))
            )
            .map((user) => ({
              id: user.id,
              name: user.name,
              roles: user.roles,
              email: user.email,
              stationId: user.stationId,
              active: user.active,
            })),
        },
      }
      setState(newState)
    }
  }, [userList])

  const isStateAvailable = () => {
    return state !== null && userList !== undefined
  }

  const handleStationUserUpdate = async (userId, inputData) => {
    await updateUser({
      variables: {
        id: userId,
        input: inputData,
        pageName: 'globalStationManager',
      },
    })
    setUnsaved(false)
  }

  const handleCreateStationUser = async (inputData) => {
    await createUser({
      variables: {
        input: inputData,
        pageName: 'globalStationManager',
      },
    })
  }

  const handleDeactivateUser = async (id: string) => {
    await deactivateUser({ variables: { id } })
  }

  return (
    <div className="w-full bg-gray-100 p-4">
      <Metadata title="Global Users" />
      <HeadingLevel level={1}>Global Users</HeadingLevel>

      <div className="mt-5 space-y-6">
        {isStateAvailable() && (
          <Card hideSeparator={true}>
            <UsersEditor
              onChange={() => setUnsaved(true)}
              onUserDataUpdated={(dataToUpdate) => {
                setState((oldValue) => ({
                  ...oldValue,
                  ['stationUsers']: dataToUpdate,
                }))
              }}
              onCancelChanges={() => setUnsaved(false)}
              data={state.users}
              updateUserData={handleStationUserUpdate}
              showNewUser={showNewUser}
              setShowNewUser={setShowNewUser}
              currentUserId={null}
              createUser={handleCreateStationUser}
              deactivateUser={handleDeactivateUser}
              roleType="GLOBAL"
              loading={
                loadingCreateUser ||
                loadingDeactivateUser ||
                loadingUpdateUser ||
                loadingUsers
              }
            />
          </Card>
        )}
      </div>
    </div>
  )
}

export default GlobalUsersPage
