import { RefObject, useEffect } from 'react'

// Detect clicks outside target ref and call callback function.
export const useOutsideClick = <T>(
  ref: RefObject<T>,
  callback: () => void,
  condition: boolean = true
) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && ref.current !== event.target) {
        callback()
      }
    }

    if (condition) document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback, condition])
}

export default useOutsideClick
