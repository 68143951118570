import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Tab, TabGroup, TabList } from '@headlessui/react'
import { AiOutlineCloseCircle as CloseIcon } from 'react-icons/ai'
import { MdDragIndicator } from 'react-icons/md'

import Button from 'src/components/Custom/Button/Button'
import CardSection from 'src/components/Custom/Card/CardSection'
import DragAndDropImageArea from 'src/components/Custom/DragAndDropImageArea/DragAndDropImageArea'
import UpdatableText from 'src/components/Custom/UpdatableText/UpdatableText'
import { transformImage } from 'src/lib/images'

import { Livestream } from './Live'

interface LiveItemProps {
  item: Livestream
  isLoading?: boolean
  updateCurrentChannel: (value, name, item) => void
  updateLivestream: (id, body) => Promise<void>
  handleImageUpload: (files, item) => void
  setActiveTabs: (value) => void
  onDelete: () => void
  renderLivestreamSourceFields: (item) => React.JSX.Element
}

const LiveItem = ({
  item,
  isLoading,
  updateCurrentChannel,
  updateLivestream,
  handleImageUpload,
  setActiveTabs,
  onDelete,
  renderLivestreamSourceFields,
}: LiveItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: item.id,
    })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  }
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }
  const nonEmptyStringRule = {
    validate: {
      text: (value: string) => {
        if (value.trim() === '') {
          return 'Please enter a non-empty string.'
        }

        return true
      },
    },
  }
  const sourceTabs = [
    {
      id: 0,
      label: 'Embed code',
      name: 'embedCode',
    },
    {
      id: 1,
      label: 'Stream URL',
      name: 'sourceUrl',
    },
    {
      id: 2,
      label: 'PBS Station Manager',
      name: 'pbsStationManager',
    },
    {
      id: 3,
      label: 'Vimeo',
      name: 'vimeoLiveEventId',
    },
  ]
  return (
    <div
      ref={setNodeRef}
      style={{
        ...style,
      }}
    >
      <div
        key={item.id}
        className="rounded-lg border border-gray-200 bg-white p-4"
      >
        <div className="flex w-full items-center justify-center">
          <MdDragIndicator
            size={40}
            className="rotate-90 transform text-gray-500"
            {...listeners}
            {...attributes}
          />
        </div>
        <CardSection title="Channel name">
          <UpdatableText
            value={item.name}
            onChange={(v) => updateCurrentChannel(v, 'name', item)}
            loadingData={isLoading}
            onSave={() =>
              updateLivestream(item.id, {
                name: item.name,
              })
            }
            validationRules={nonEmptyStringRule}
          />
        </CardSection>
        <div className="h-[1px] w-full bg-gray-200" />
        <CardSection title="Channel logo">
          <div className="mb-2 flex flex-col">
            <span className="text-sm">Logo</span>
            <div className="flex flex-col lg:flex-row">
              <div className="mb-2 w-full lg:w-1/2">
                <div className="mt-6">
                  {item.logo ? (
                    <img
                      src={transformImage({
                        imageUrl: item.logo,
                        options: { width: 150 },
                      })}
                      alt="primary logo"
                    />
                  ) : (
                    <em>Not set</em>
                  )}
                </div>
              </div>
              <div className="w-full lg:w-1/2">
                <DragAndDropImageArea
                  id={`${item.name} logo`}
                  onUpload={(files) => handleImageUpload(files, item)}
                />
              </div>
            </div>
          </div>
        </CardSection>
        <div className="h-[1px] w-full bg-gray-200" />
        <CardSection title="Description">
          <UpdatableText
            value={item.stationDescription}
            onChange={(v) =>
              updateCurrentChannel(v, 'stationDescription', item)
            }
            textArea
            loadingData={isLoading}
            onSave={() =>
              updateLivestream(item.id, {
                description: item.stationDescription,
              })
            }
            validationRules={nonEmptyStringRule}
          />
        </CardSection>
        <div className="h-[1px] w-full bg-gray-200" />
        <CardSection title="Short description">
          <UpdatableText
            value={item.shortStationDescription}
            onChange={(v) =>
              updateCurrentChannel(v, 'shortStationDescription', item)
            }
            textArea
            loadingData={isLoading}
            onSave={() =>
              updateLivestream(item.id, {
                shortDescription: item.shortStationDescription,
              })
            }
            validationRules={nonEmptyStringRule}
          />
        </CardSection>
        <div className="h-[1px] w-full bg-gray-200" />
        <CardSection title="Schedule Feed ID">
          <UpdatableText
            value={item.pbsTvssFeedCid}
            onChange={(v) => updateCurrentChannel(v, 'pbsTvssFeedCid', item)}
            loadingData={isLoading}
            onSave={() =>
              updateLivestream(item.id, {
                pbsTvssFeedCid: item.pbsTvssFeedCid,
              })
            }
          />
        </CardSection>
        <div className="h-[1px] w-full bg-gray-200" />
        <CardSection title="Sources">
          <span className="text-sm text-gray-500">
            Livestream source order of preference:
            <ol className="list-inside list-decimal">
              <li>Embed code (web only)</li>
              <li>Stream URL</li>
              <li>PBS Station Manager</li>
              <li>Vimeo</li>
            </ol>
          </span>

          <TabGroup
            onChange={(i) =>
              setActiveTabs((prev) => ({ ...prev, [item.id]: i }))
            }
          >
            <TabList className="mt-4 flex space-x-4 rounded-xl bg-white p-1">
              {sourceTabs.map((tab) => (
                <Tab
                  key={tab.id}
                  className={({ selected }) =>
                    classNames(
                      'rounded-lg px-4 py-2.5 text-sm font-medium leading-5',
                      'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                      selected
                        ? 'bg-indigo-100 text-indigo-700'
                        : 'text-gray-500 hover:bg-indigo-100/20'
                    )
                  }
                >
                  {tab.label}
                </Tab>
              ))}
            </TabList>
          </TabGroup>

          {renderLivestreamSourceFields(item)}
        </CardSection>
        <div className="my-5 flex w-full items-center justify-end">
          <Button
            title={`Delete ${item.name}`}
            outlineColor="border-red-600 text-red-600 hover:border-red-700 hover:text-red-700"
            icon={<CloseIcon size="20px" />}
            onClick={onDelete}
          />
        </div>
      </div>
    </div>
  )
}

export default LiveItem
