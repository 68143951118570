import CardSection from 'src/components/Custom/Card/CardSection'
import UpdatableText from 'src/components/Custom/UpdatableText/UpdatableText'
import { StationUrlProfiles } from 'src/lib/urls'

import { StationInputType, UrlsType } from '../../../common/types'

export interface LinksData {
  [StationUrlProfiles.ACTIVATION_REDIRECT]: string | null
  [StationUrlProfiles.CONTACT]: string | null
  [StationUrlProfiles.DONATE]: string | null
  [StationUrlProfiles.HELP]: string | null
  [StationUrlProfiles.PASSPORT]: string | null
  [StationUrlProfiles.PRIVACY_POLICY]: string | null
  [StationUrlProfiles.TERMS_OF_USE]: string | null
  urls: UrlsType[]
}

interface LinksProps {
  data: LinksData
  onChange: (data: LinksData) => void
  updateStation: (inputData: StationInputType) => Promise<void>
  loadingUpdateStation: boolean
  stationCallSign?: string
}

const Links = ({
  data,
  onChange,
  updateStation,
  loadingUpdateStation,
  stationCallSign,
}: LinksProps) => {
  const updateData = (value: string, profile: string) => {
    const _data = { ...data }
    if (!value) {
      delete _data[profile]
    } else {
      _data[profile] = value
    }
    onChange(_data)
  }

  const prepareUrls = (profileInput: string) => {
    const urls = [...data.urls]
    const itemIndex = urls.findIndex((url) => url.profile === profileInput)

    // Remove URL if undefined.
    if (!data[profileInput]) {
      return urls.filter((url) => url.profile !== profileInput)
    }

    if (itemIndex > -1) {
      // If URL exists, update it.
      return urls.map((url, index) =>
        index === itemIndex
          ? { ...url, ...{ profile: profileInput, url: data[profileInput] } }
          : url
      )
    } else {
      return [...urls, { profile: profileInput, url: data[profileInput] }]
    }
  }

  const updatedTextProps = (profile: string) => {
    return {
      value: data[profile],
      onChange: (v: string) => updateData(v, profile),
      onSave: () => updateStation({ urls: prepareUrls(profile) }),
      validationRules: {
        validate: {
          url: (value: string) => {
            // Allow users to unset field.
            if (value.length === 0) return true
            const urlPattern = /^http/
            if (!value || !urlPattern.test(value)) {
              return 'Please enter a valid URL'
            }
            return true
          },
        },
      },
      loadingData: loadingUpdateStation,
    }
  }

  const activateUrl = `${process.env.ACTIVATE_DEVICE_URL}/${stationCallSign || '{CALL SIGN}'}`

  return (
    <div className="w-full">
      {/** TODO: Make this dynamic according to given props without causing re-renders */}
      <CardSection
        title="Activation redirect"
        subtitle={`The link displayed on device activation screen in apps. This link should redirect to ${activateUrl}.`}
      >
        <UpdatableText
          {...updatedTextProps(StationUrlProfiles.ACTIVATION_REDIRECT)}
        />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="Contact">
        <UpdatableText {...updatedTextProps(StationUrlProfiles.CONTACT)} />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="General donation">
        <UpdatableText {...updatedTextProps(StationUrlProfiles.DONATE)} />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="Help">
        <UpdatableText {...updatedTextProps(StationUrlProfiles.HELP)} />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="Passport">
        <UpdatableText {...updatedTextProps(StationUrlProfiles.PASSPORT)} />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="Privacy policy">
        <UpdatableText
          {...updatedTextProps(StationUrlProfiles.PRIVACY_POLICY)}
        />
      </CardSection>
      <div className="h-[1px] w-full bg-gray-200" />
      <CardSection title="Terms of use">
        <UpdatableText {...updatedTextProps(StationUrlProfiles.TERMS_OF_USE)} />
      </CardSection>
    </div>
  )
}

export default Links
